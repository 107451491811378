import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import useScreen from 'src/hooks/useScreen';
import { CreateChefForm } from './CreateChefModal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface ChefAboutProp {
  isMobile?: boolean;
  onBack: (about: string, education: string) => void;
  formValue: CreateChefForm;
  onFormSubmit: (about: string, education: string) => void;
}

interface ChefAboutForm {
  about: string;
  education: string;
}

const aboutchema = yup
  .object({
    about: yup.string().required(),
    education: yup.string().required().label('Education'),
  })
  .required();

const ChefAbout: FunctionComponent<ChefAboutProp> = ({
  isMobile,
  onBack,
  formValue,
  onFormSubmit,
}) => {
  const { height } = useScreen(100);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ChefAboutForm>({
    resolver: yupResolver(aboutchema),
    defaultValues: {
      about: formValue.about,
      education: formValue.education,
    },
  });

  const onSubmit = (data: ChefAboutForm) => {
    onFormSubmit(data.about, data.education);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      component="form"
      height={isMobile ? height : '750px'}
      paddingTop={6}
      paddingBottom={{ xs: 5, md: 3 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item>
        <Box marginBottom={2}>
          <Typography variant="h4" gutterBottom>
            {`We'd love to know more about your culinary journey.`}
          </Typography>
          <Typography mt={4} variant="h6" gutterBottom>
            Who are you and what makes you unique?
          </Typography>
        </Box>
        <Typography variant="body2" component="p">
          Tell diners about yourself! This can include educational background, culinary
          achievements, awards and professional opportunities.
        </Typography>
        <Box paddingY={4}>
          <Controller
            control={control}
            name="about"
            render={({ field }) => {
              return (
                <TextField
                  multiline
                  rows={5}
                  fullWidth
                  placeholder="e.g. the chef to be awarded as the winner of the ASIA YOUTH HOPE COOKING"
                  {...field}
                  error={!!errors.about?.message}
                  helperText={errors.about?.message}
                />
              );
            }}
          />
        </Box>
        <Box pb={4}>
          <Controller
            name="education"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Education background"
                fullWidth
                error={!!errors?.education}
                helperText={errors?.education?.message}
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  onBack(getValues().about, getValues().education);
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" fullWidth variant="contained" size="large">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChefAbout;
