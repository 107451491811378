import { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';

const ChefAlreadyRegistered: FunctionComponent = () => {
  const router = useRouter();
  return (
    <Grid
      container
      direction="column"
      justifyContent={'space-between'}
      alignItems="center"
      sx={{
        pt: 8,
        pb: 3,
      }}
    >
      <Grid item>
        <Typography variant="h4">Chef registered</Typography>
      </Grid>
      <Grid item mt={3}>
        <Typography variant="body1">You have already registered as a chef</Typography>
      </Grid>
      <Grid item mt={10}>
        <Button variant="contained" color="primary" onClick={() => router.push('/profile/0')}>
          Go to my portfolio
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChefAlreadyRegistered;
