import { FunctionComponent, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { login_with_facebook } from '@services/auth';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { authState } from '@atoms/auth';
import FacebookEmailModal from './FacebookEmailModal';
import FacebookLogin from 'react-facebook-login';

const FacebookSignIn = styled(Button)({
  backgroundColor: '#1877F2',
  boxShadow: 'none',
  justifyContent: 'start',
  color: '#FFFFFF',
  fontSize: 15,
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#1877F2',
  },
});

interface FacebookSignInButtonProp {
  onSignInSuccess?: () => void;
  isSignIn?: boolean;
}

const FacebookSignInButton: FunctionComponent<FacebookSignInButtonProp> = ({
  onSignInSuccess,
  isSignIn,
}) => {
  const setAuth = useSetRecoilState(authState);

  const [isOpen, setIsOpen] = useState(false);
  const [jwtToken, setJwtToken] = useState('');
  const [rft, setRft] = useState('');
  const [isAuth, setIsAuth] = useState(true);
  const auth = useRecoilValue(authState);
  const [openFB, setOpenFB] = useState(false);

  const onSuccess = async (res: any) => {
    const { accessToken } = res;
    const act = localStorage.getItem('act');

    if (!act) {
      const redirectUrl = window.location.href ?? '';

      const { code, message, data } = await login_with_facebook(accessToken, redirectUrl);

      if (code === '200' && data.length > 0) {
        localStorage.setItem('act', data[0].jwt);
        localStorage.setItem('rft', data[0].refresh);
        setAuth({ auth: true, loading: false });
        if (onSignInSuccess) onSignInSuccess();
      } else if (code === '11802') {
        setJwtToken(data[0].jwt);
        setRft(data[0].refresh);
        setIsOpen(true);
      } else {
        alert(message);
      }
    }
  };

  useEffect(() => {
    const act = localStorage.getItem('act');
    if (act) {
      console.log('act', act);
      setIsAuth(true);
    } else {
      console.log('fail');
      setIsAuth(false);
    }
  }, []);

  const onFacebookSignInSuccess = () => {
    localStorage.setItem('act', jwtToken);
    localStorage.setItem('rft', rft);
    setAuth({ auth: true, loading: false });
    if (onSignInSuccess) onSignInSuccess();
  };
  return (
    <>
      {
        <>
          {openFB && (
            <>
              <FacebookLogin
                appId={process.env.NEXT_PUBLIC_FACEBOOK_APPLICATION_ID || ''}
                autoLoad={true}
                fields="name,email,picture"
                callback={onSuccess}
                cssClass="facebook-login1"
              />
              <FacebookLogin
                appId={process.env.NEXT_PUBLIC_FACEBOOK_APPLICATION_ID || ''}
                autoLoad={true}
                fields="name,email,picture"
                callback={onSuccess}
                cssClass="facebook-login2"
              />
              <FacebookLogin
                appId={process.env.NEXT_PUBLIC_FACEBOOK_APPLICATION_ID || ''}
                autoLoad={true}
                fields="name,email,picture"
                callback={onSuccess}
                cssClass="facebook-login"
              />
            </>
          )}

          <FacebookSignIn
            variant="contained"
            size="large"
            onClick={() => {
              setOpenFB(true);
              console.log('click');
              setTimeout(() => {
                const ele = document.getElementsByClassName('facebook-login')[0] as HTMLElement;
                console.log(ele);
                ele.click();
              }, 1000);
            }}
            startIcon={
              <img
                src="/images/logos/facebook-logo.svg"
                alt="facebook logo"
                style={{ marginRight: 8 }}
              />
            }
            fullWidth
          >
            Continue with Facebook
          </FacebookSignIn>
        </>
      }

      {isOpen && (
        <FacebookEmailModal
          isOpen={isOpen}
          onClose={() => {
            onFacebookSignInSuccess();
            setIsOpen(false);
          }}
          token={jwtToken}
          isAuth={auth.auth}
        />
      )}
    </>
  );
};

export default FacebookSignInButton;
