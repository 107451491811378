import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { resend_verify_email } from '@services/auth';
import { FunctionComponent, useState } from 'react';
import useScreen from 'src/hooks/useScreen';

interface VerifyYourEmailProp {
  isMoblie?: boolean;
  email: string;
}

const VerifyYourEmail: FunctionComponent<VerifyYourEmailProp> = ({ isMoblie, email }) => {
  const { height } = useScreen(100);
  const [count, setCount] = useState<number>(0);

  const onResendPassword = () => {
    setCount((prev) => prev + 1);

    resend_verify_email(email);
  };

  return (
    <Container maxWidth="xs">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height={isMoblie ? height : '662px'}
        width={isMoblie ? '100%' : '396px'}
      >
        <Grid item>
          <Grid container justifyContent="center" marginBottom={(theme) => theme.spacing(2)}>
            <Grid item>
              <img src="/icons/ic_warning.svg" alt="warning" />
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            gutterBottom
            align="center"
            marginBottom={(theme) => theme.spacing(2)}
          >
            Please verify your email
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            align="center"
            color="#637381"
            marginBottom={(theme) => theme.spacing(4)}
          >
            We’ve sent a verification link to your inbox
          </Typography>
          <Typography variant="body2" color="#212B36" align="center">
            Don’t receive an email?{' '}
            <span
              style={{
                textDecoration: 'underline',
                fontWeight: 'bold',
                cursor: count < 3 ? 'pointer' : undefined,
                color: count < 3 ? '#373737' : '#637381',
              }}
              onClick={() => {
                if (count < 3) onResendPassword();
              }}
            >
              Resend email
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VerifyYourEmail;
