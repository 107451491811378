import { m, AnimatePresence, AnimatePresenceProps } from 'framer-motion';
// @mui
import { Dialog, Box, Paper, DialogProps } from '@mui/material';
//
import { varFade } from './variants';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
// ----------------------------------------------------------------------

export interface Props extends DialogProps {
  variants?: Record<string, unknown>;
  onClose?: VoidFunction;
  dialogProps?: Pick<DialogProps, 'sx'>;
}

export default function DialogAnimate({
  open = false,
  variants,
  onClose,
  children,
  dialogProps,
  sx,
  ...other
}: Props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const animateProps = useMemo(() => {
    const inUps = {
      ...(variants ||
        varFade({
          distance: 120,
          durationIn: 0.32,
          durationOut: 0.24,
          easeIn: 'easeInOut',
        }).inUp),
    };
    return {
      ...inUps,
      initial: 'hidden',
      whileInView: 'visible',
      viewport: { once: true },
    };
  }, [variants]);

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          fullScreen={!isDesktop}
          fullWidth={!isDesktop}
          maxWidth={!isDesktop ? 'sm' : 'md'}
          open={open}
          onClose={onClose}
          PaperProps={
            !isDesktop
              ? {
                  style: { borderRadius: '20px 20px 0 0' },
                }
              : undefined
          }
          PaperComponent={(props) => (
            <Box
              component={m.div}
              {...animateProps}
              sx={{
                width: '100%',
                height: isDesktop ? '100%' : undefined,
                display: isDesktop ? 'flex' : undefined,
                alignItems: isDesktop ? 'center' : undefined,
                justifyContent: isDesktop ? 'center' : undefined,
                position: isDesktop ? undefined : 'fixed',
                bottom: isDesktop ? undefined : 0,
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
          {...(dialogProps ?? {})}
        >
          {children}
        </Dialog>
      )}
    </AnimatePresence>
  );
}
