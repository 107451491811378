import Head from 'next/head';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
// @mui
import { Box, BoxProps, Snackbar } from '@mui/material';
import WithNavbarLayout from 'src/layouts/WithNavbarLayout';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { authState, chefRegisteredState } from '@atoms/auth';
import { get_access_token } from '@services/auth';
import { useRouter } from 'next/router';
import { get_current_profile } from '@services/profile';
import { profileState } from '@atoms/profile';
import Loading from './page/Loading';
import TermsAndConditionsAcceptModal from '@components/terms/TermsAndConditionsAcceptModal';
import { description } from 'src/_mock/text';
import { validateTokenLogin } from '@services/auth';

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode;
  title?: string;
  footer?: boolean;
  auth?: boolean;
  redirect?: string;
  isLayerOnePage?: boolean;
  hideLoader?: boolean;
  image?: string;
  description?: string;
  isLanding?: boolean;
  hideSearch?: boolean;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = '',
      footer,
      auth,
      redirect = '/',
      isLayerOnePage,
      hideLoader,
      description,
      image,
      isLanding,
      hideSearch,
      ...other
    },
    ref
  ) => {
    const router = useRouter();
    const { token } = router.query;
    const [auths, setAuth] = useRecoilState(authState);
    const [oneTimeCheck, setOneTimeCheck] = useState<boolean>(true);
    const [loadingCheckToken, setLoadingCheckToken] = useState<boolean>(true);

    const [profle, setProfile] = useRecoilState(profileState);
    const resetProfile = useResetRecoilState(profileState);
    const setChefRegisteredState = useSetRecoilState(chefRegisteredState);

    const [isShowAcceptCookies, setIsShowAcceptCookies] = useState<boolean>(false);

    const onAccept = () => {
      localStorage.setItem('cookies', 'true');

      setIsShowAcceptCookies(false);
    };

    useEffect(() => {
      // console.log(auth, token, oneTimeCheck);

      if (router.pathname !== '/account/verify-reset-password-token-email' && token) {
        if (oneTimeCheck) {
          setLoadingCheckToken(true);
          if (!auths.auth && token) {
            validateTokenLogin(token as string).then((res) => {
              if (res) {
                setAuth({ auth: true, loading: true });
                setOneTimeCheck(false);
                setLoadingCheckToken(false);
              }
            });
          } else {
            setOneTimeCheck(false);
            setLoadingCheckToken(false);
          }
        } else {
          setLoadingCheckToken(false);
        }
      } else {
        setLoadingCheckToken(false);
      }
    }, [router.pathname, auths, oneTimeCheck, setAuth, token, setOneTimeCheck]);

    useEffect(() => {
      if (
        router.pathname !== '/account/verify-reset-password-token-email' &&
        auths.auth &&
        !!profle.id
      ) {
        return;
      }
      const accessToken = token ? token : '';

      const pathCheck = () => {
        const prevPage = localStorage.getItem('prevPage');
        if (accessToken) {
          if (prevPage) {
            const prevPath = prevPage.split('?')[0];
            const currentPath = router.asPath.split('?')[0];
            // localStorage.removeItem('prevPage');
            if (prevPath !== currentPath) {
              router.push(`/${prevPage}`);
              localStorage.removeItem('prevPage');
            }
          } else {
            localStorage.setItem('prevPage', router.asPath);
          }
        }
      };
      get_access_token().then((token) => {
        if (token) {
          pathCheck();
          get_current_profile(token).then((res) => {
            if (res?.code !== '200') {
              return;
            }
            setProfile(res.data);
            setAuth({ auth: true, loading: false });
            setChefRegisteredState(res.data.isChef);
          });
        } else {
          resetProfile();
          setAuth({ auth: false, loading: false });
          if (auth) {
            pathCheck();
            console.log(accessToken, token);
            if (accessToken !== '') {
              validateTokenLogin(accessToken as string).then((res) => {
                console.log(res);
                if (!res) {
                  console.log('redirect - if');
                  // router.push(redirect);
                }
              });
            } else {
              console.log('redirect - else');
              // router.push(redirect);
            }
          }
          const accept = localStorage.getItem('cookies');

          setIsShowAcceptCookies(accept !== 'true');
        }
      });
    }, [
      auth,
      token,
      auths.auth,
      profle.id,
      redirect,
      resetProfile,
      router,
      setAuth,
      setChefRegisteredState,
      setProfile,
    ]);

    return (
      <Box ref={ref} {...other}>
        <Head>
          <title>{`${title}`}</title>
          {title && <meta property="og:title" content={title} />}
          {description && <meta property="og:description" content={description} />}
          {image && <meta property="og:image" content={image} />}
          {
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          }
        </Head>
        <WithNavbarLayout
          hideSearch={hideSearch}
          footer={footer}
          isLayerOnePage={isLayerOnePage}
          isLanding={isLanding}
        >
          {(auths.loading && !hideLoader) || loadingCheckToken ? <Loading /> : children}
        </WithNavbarLayout>
        <Snackbar
          open={isShowAcceptCookies}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ zIndex: 0 }}
        >
          <TermsAndConditionsAcceptModal onAccept={onAccept} />
        </Snackbar>
      </Box>
    );
  }
);

export default Page;
