import Modal from '@components/Modal';
import useResponsive from '@hooks/useResponsive';
import { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { update_email_profile } from '@services/profile';

interface SignInSignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  token: string;
  isAuth: boolean;
}

const FacebookEmailModal: FunctionComponent<SignInSignUpModalProps> = ({
  isOpen,
  onClose,
  isAuth,
  token,
}) => {
  const isMoblie = useResponsive('down', 'sm');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const onModalClose = () => {
    onClose();
  };

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onConfirm = async () => {
    if (!validateEmail(email)) {
      setError(true);
      return;
    } else {
      setError(false);
      const res = await update_email_profile(token, email);
      if (res.code === '200') {
        onClose();
      } else {
        alert(res.message);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} maxWidth="xs" fullWidth>
      <Grid
        container
        spacing={2}
        my={{
          xs: 0,
          sm: 5,
          md: 6,
        }}
        sx={{
          height: isMoblie ? 'auto' : 590,
        }}
      >
        <Grid
          item
          xs={12}
          mx={5}
          mt={{
            xs: 10,
            sm: 10,
            md: 10,
          }}
        >
          <Typography variant="h4" component="h2" align="left">
            Your email
          </Typography>
          <Typography variant="body2" color="#637381" align="left" mt={1}>
            Please provide us your email to receive notifications regarding your dinner booking(s)
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            sx={{
              mt: 3,
            }}
            onChange={(e) => {
              if (!validateEmail(e.target.value)) {
                setError(true);
              } else {
                setError(false);
              }
              setEmail(e.target.value);
            }}
            error={error}
            helperText={error ? 'Please enter a valid email' : ''}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 26, color: '#D7D3CC', py: 1.5 }}
            onClick={onConfirm}
          >
            Confirm Email
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FacebookEmailModal;
