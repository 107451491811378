import { get } from '@utils/fetch';

export interface Province {
  id: number;
  name: string;
  isActive: boolean;
  countryId: number;
}

export async function get_province() {
  return await get<Province[]>(`${process.env.NEXT_PUBLIC_API_URL}/data/province`);
}
