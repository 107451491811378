import { get, post } from '@utils/fetch';
import { Image } from './upload';

export interface UnreadNotification {
  unread: number;
}

export async function get_unread_notification(token: string) {
  return await get<UnreadNotification[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/notification/number-of-unread`,
    token.length > 0
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined
  );
}

export interface NotificationItem {
  id: number;
  type: string;
  refId: number;
  title: string;
  description: string;
  isRead: boolean;
  imageThumbnail: Image;
  createdAt: string;
  receiverName?: string;
  remark?: string;
  redirectUrl?: string;
}

export interface NotificationResponse {
  total: number;
  data: NotificationItem[];
}

export async function get_notifications(token: string, page: number, limit: number) {
  return await get<NotificationResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/notification?limit=${limit}&page=${page}`,
    token.length > 0
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined
  );
}

export async function post_notification_read(token: string, notificationId: number) {
  return await post<any>(
    `${process.env.NEXT_PUBLIC_API_URL}/notification/${notificationId}/read`,
    {},
    token.length > 0
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined
  );
}

export async function post_notification_read_all(token: string) {
  return await post<any>(
    `${process.env.NEXT_PUBLIC_API_URL}/notification/all-read`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}
