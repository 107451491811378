import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import useScreen from 'src/hooks/useScreen';
import { Languages } from 'src/services/languages';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { CreateChefForm } from './CreateChefModal';
import Skeleton from '@mui/material/Skeleton';
import ChipWrap from '@components/course/ChipWrap';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ChefLanguagesProps {
  formValue: CreateChefForm;
  onFormSubmit: (languageSpeak: number[]) => void;
  languageList: Languages[];
  isMobile?: boolean;
  onBack: () => void;
  onNext: () => void;
}

interface LanguageForm {
  languageSpeak: number[];
}

const cuisinetype = yup
  .object({
    languageSpeak: yup.array(yup.number()).min(1).required(),
  })
  .required();

const ChefLanguages: FunctionComponent<ChefLanguagesProps> = ({
  isMobile,
  onBack,
  onNext,
  formValue,
  onFormSubmit,
  languageList: initLanguages,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { height } = useScreen(100);

  const [isLoadingLanguage, setIsLoadingLanguage] = useState<boolean>(true);
  const [languageList, setLanguageList] = useState<Languages[]>([]);

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<LanguageForm>({
    resolver: yupResolver(cuisinetype),
    defaultValues: {
      languageSpeak: formValue.languageSpeak,
    },
  });

  const onSubmit = (data: LanguageForm) => {
    onFormSubmit(data.languageSpeak);
    onNext();
  };

  useEffect(() => {
    setLanguageList(initLanguages);
    setTimeout(() => setIsLoadingLanguage(false), 500);
  }, [initLanguages]);

  useEffect(() => {
    setValue(
      'languageSpeak',
      languageList.filter((v) => v.isSelected).map((i) => i.id)
    );
  }, [languageList, setValue]);

  const updateLanguages = useCallback(() => {
    const data = formValue.languageSpeak;

    const prevTypeOfCuisine = (prev: Languages[], start: number, end: number) => {
      return (
        prev
          ?.map((v) => {
            const index = data.findIndex((f) => f === v.id);
            return index !== -1 ? { ...v, isSelected: true } : { ...v, isSelected: false };
          })
          ?.sort((x, y) => (x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1))
          ?.slice(start, end) || []
      );
    };

    setLanguageList((prev) => prevTypeOfCuisine(prev, 0, 9));
  }, [formValue]);

  useEffect(() => {
    if (!isLoadingLanguage) {
      updateLanguages();
    }
  }, [isLoadingLanguage, updateLanguages]);

  const onSuggestCuisine = (id: number): void => {
    setLanguageList((prev) =>
      prev.map((v) => {
        if (v.id === id) v.isSelected = !v.isSelected;
        return v;
      })
    );
  };

  return (
    <>
      <Grid
        container
        direction="column"
        component="form"
        height={isMobile ? height : '750px'}
        paddingTop={6}
        paddingBottom={{ xs: 2, md: 0 }}
        flexWrap="nowrap"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item>
          <Typography variant="h4">Tell us the languages you can speak with your diners</Typography>
        </Grid>
        <Grid item flex="1 1 auto" overflow="auto" sx={{ maxWidth: '370px !important' }}>
          {languageList?.length > 0 && (
            <Box
              sx={{
                '> div': {
                  mr: 0.8,
                  mb: 0.8,
                },
                pt: 1.5,
                pb: 1.5,
              }}
            >
              {isLoadingLanguage ? (
                <>
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} width="60%" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation="wave" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} width="80%" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} width="50%" />
                </>
              ) : (
                languageList.map((v) => (
                  <ChipWrap
                    isSelected={v.isSelected}
                    key={`cuisine_type_` + v.id}
                    label={v.name}
                    onDelete={() => onSuggestCuisine(v.id)}
                    onClick={() => onSuggestCuisine(v.id)}
                  />
                ))
              )}
              <div {...register('languageSpeak')} />
            </Box>
          )}
        </Grid>
        <Grid item pt={2} pb={3}>
          {errors.languageSpeak && (
            <Box pb={3}>
              <Alert severity="error">Please select at least one type</Alert>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  onFormSubmit(getValues().languageSpeak);
                  onBack();
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" fullWidth variant="contained" size="large">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChefLanguages;
