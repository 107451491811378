import { get, post, put } from '@utils/fetch';
import { Ingredient } from './ingredient';
import { Image } from './upload';
import { Profile } from '@typings/profile';

export interface Chef {
  id: number;
  name: string;
  phoneNumber: string;
  imageCover?: Image;
  countryCode: string | null;
}

export interface VenueOwner {
  id: number;
  email: string;
  name: string;
  phoneNumber: string;
  isPhoneNumberVerified: boolean;
}

export interface UpdateProfile {
  allergicIds: number[];
  chefPhoneNumber?: string;
  email: string;
  firstName: string;
  imageProfileId: number;
  imageCoverId: number;
  lastName: string;
  numberOfVaccination: number;
  phoneNumber: string;
  countryCode: string;
  remark: string;
}

export async function get_current_profile(token: string) {
  return get<Profile>(`${process.env.NEXT_PUBLIC_API_URL}/user`, {
    Authorization: `Bearer ${token}`,
  });
}

export async function update_current_profile(token: string, profile: UpdateProfile) {
  return put(`${process.env.NEXT_PUBLIC_API_URL}/user`, profile, {
    Authorization: `Bearer ${token}`,
  });
}

export async function update_email_profile(token: string, email: string) {
  return put(
    `${process.env.NEXT_PUBLIC_API_URL}/register/update-email`,
    {
      email: email,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export interface ShortProfile {
  id: number;
  firstName: string;
  lastName: string;
  imageProfileUrl: string;
  imageUrl: string;
  thumbnailUrl: string;
  displayName: string;
}

export async function get_short_profile(token: string, uids: number[]) {
  return post<ShortProfile[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/user-short`,
    {
      userIds: uids,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}
