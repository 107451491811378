const SIZES = [320, 480, 640, 750, 1080, 1440];

export interface Source {
  src: string;
  srcSet?: string;
}

export function get_srcset(url: string): Source {
  url = url_format(url);

  const srcsets = SIZES.map((width) => {
    return `${url}?tr=w-${width}.4q-10,fo-auto ${width}w`;
  });

  return {
    src: url,
    srcSet: srcsets.join(','),
  };
}

export function url_format(url: string) {
  return url?.replace(/ /g, '%20');
}

export interface resizeImageProps {
  url: string;
  width?: number;
  height?: number;
}

export function resize_image({ url, width, height }: resizeImageProps): string {
  // image 5x
  const size = 5;
  width = width ? width * size : undefined;
  height = height ? height * size : undefined;

  return url + `?tr=` + (width ? `w-${width},` : ``) + (height ? `h-${height}` : ``) + ',fo-auto';
}
