import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import { Button } from '@mui/material';

interface CheckYourEmailProps {
  email: string;
  onTryAgain: () => void;
  onBackToLogin?: () => void;
}

const CheckYourEmail: FunctionComponent<CheckYourEmailProps> = ({
  email,
  onTryAgain,
  onBackToLogin,
}) => (
  <Container maxWidth="sm">
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Box py={3}>
          <Grid container justifyContent="center" mb={5}>
            <Grid item>
              <img src="/icons/ic_mail.svg" alt="mail" />
            </Grid>
          </Grid>
          <Box mb={4}>
            <Typography variant="h3" gutterBottom align="center" marginBottom={2}>
              Please check your email modal!
            </Typography>
            <Typography variant="body2" align="center" color="#637381">
              We sent an email to {email}!
            </Typography>
            <Typography variant="body2" align="center" color="#637381">
              If this email is connected to a Potioneer account
            </Typography>
            <Typography variant="body2" align="center" color="#637381">
              you&apos;ll be able to reset your password
            </Typography>
          </Box>
          <Button variant="contained" size="large" fullWidth onClick={onBackToLogin}>
            Back to log In
          </Button>
          <Grid container justifyContent="center" mt={1}>
            <Grid item>
              <span color="#373737" style={{ fontSize: 14 }}>
                Don&apos;t receive an email?{' '}
                <span
                  onClick={onTryAgain}
                  color="#373737"
                  style={{
                    fontSize: 14,
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  Try again
                </span>
              </span>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default CheckYourEmail;
