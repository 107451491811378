import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { resize_image } from '@utils/image';

const LogoStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingX: '20px',
  marginBottom: '5px',
}));

const PotioneerFooter = () => {
  return (
    <Grid container justifyContent="center" p={7}>
      <Grid container flexDirection="column" alignItems="center">
        <LogoStyle>
          <img
            alt="potioneer"
            src={resize_image({
              url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
              width: 40,
              height: 40,
            })}
            width={40}
          />
        </LogoStyle>
        <img alt="copyright" src="/images/copyright.svg" />
      </Grid>
    </Grid>
  );
};

export default PotioneerFooter;
