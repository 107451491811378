export const popularCuisineOptions = [
  { label: 'Chinese', value: 'Chinese' },
  { label: 'Italian', value: 'Italian' },
  { label: 'Thai', value: 'Thai' },
  { label: 'Japanese', value: 'Japanese' },
  { label: 'European', value: 'European' },
  { label: 'Fusion', value: 'Fusion' },
];

export const venueOptions = [
  { label: 'Any', value: '' },
  { label: 'Venue Partner', value: 'Potioneer Partner Venue' },
  { label: 'Chef’s restaurant', value: 'CHEF' },
  { label: 'Chef at your location', value: 'YOUR_LOCATION' },
];

export const timeOptions = [
  { label: 'Any', value: '' },
  { label: 'Lunch ( 11:00 - 14:59 )', value: 'LUNCH' },
  { label: 'Dinner ( 15:00 - 21:59 )', value: 'DINNER' },
];

export const priceOptions = [
  { label: 'Any', value: '' },
  { label: 'Under 1,500', value: '0-1500' },
  { label: '1,501-2,500', value: '1501-2500' },
  { label: '2,501-4,000', value: '2501-4000' },
  { label: '4,001-5,000', value: '4001-5000' },
  { label: 'Above 5,000', value: '5001' },
];
