import { atom } from 'recoil';

export const authState = atom({
  key: 'AUTH_STATE',
  default: {
    auth: false,
    loading: true,
  },
});

interface ISignUpSignInModalState {
  open: boolean;
  redirect?: string;
  refId?: string;
  mode:
    | 'sign-in'
    | 'forgot-password'
    | 'request-invitation'
    | 'check-reset-password-email'
    | 'sign-up'
    | 'verify-your-emil';
}

export const signUpSignInModalState = atom<ISignUpSignInModalState>({
  key: 'SIGN_UP_SIGN_IN_MODAL_STATE',
  default: {
    open: false,
    redirect: undefined,
    refId: undefined,
    mode: 'sign-in',
  },
});

export const signInIgState = atom({
  key: 'AUTH_IG_STATE',
  default: {
    auth: false,
    message: '',
  },
});

export const chefRegisteredState = atom({
  key: 'IS_CHEF_STATE',
  default: false,
});
