import { get, post } from '@utils/fetch';

export interface Languages {
  id: number;
  name: string;
  isSelected?: boolean;
  // isActive: boolean;
}

export async function get_languages() {
  return await get<Languages[]>(`${process.env.NEXT_PUBLIC_API_URL}/data/languages`);
}

// export function create_languages(data: { name: string }) {
//   return post<languages[]>(`${process.env.NEXT_PUBLIC_API_URL}/data/languages`, data);
// }
