import { styled } from '@mui/material/styles';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import PotioneerAppBarV2 from './AppBarV2';
import PotioneerFooter from './Footer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { searchbarAtom } from '@atoms/searchbar';
import { searchbarOnKeyboardAtom } from '@atoms/searchbar';
import { DASHBOARD_NAVBAR_WIDTH } from '../config';

interface MainStyleProps {
  searchbarexpanded: number;
  hidesearchbar: number;
  iscoursemenu: boolean;
}

const RootStyle = styled('div')(({ theme }) => ({ height: '100%', backgroundColor: '#EFEBE5' }));

const MainStyle = styled('main')<MainStyleProps>(
  ({ theme, searchbarexpanded, hidesearchbar, iscoursemenu }) => ({
    flexGrow: 1,
    paddingTop: hidesearchbar === 1 ? '56px' : searchbarexpanded === 1 ? '160.13px' : '56px',
    height: '100%',
    // transition: theme.transitions.create('padding-top', {
    //   duration: 0.2,
    // }),
    transition: 'all 0.5s ease-in-out 0s',
    [theme.breakpoints.up('sm')]: {
      paddingTop: hidesearchbar === 1 ? '80px' : searchbarexpanded === 1 ? '218px' : '80px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      // transition: theme.transitions.create(['margin-left', 'padding-top'], {
      //   duration: theme.transitions.duration.shorter,
      // }),
      // transition: 'all 0.3s',

      paddingLeft: iscoursemenu ? `${DASHBOARD_NAVBAR_WIDTH}px` : '0px',
      // width: `calc(100% - ${DASHBOARD_NAVBAR_WIDTH}px)`,
    },
  })
);

interface WithNavbarLayoutProp {
  footer?: boolean;
  isLayerOnePage?: boolean;
  isLanding?: boolean;
  hideSearch?: boolean;
}

const WithNavbarLayout: FunctionComponent<WithNavbarLayoutProp> = ({
  children,
  footer,
  isLayerOnePage,
  isLanding,
  hideSearch,
}) => {
  const [searchbarState, setSearchbarState] = useRecoilState(searchbarAtom);
  const [scrollPosition, setSrollPosition] = useState(0);
  const searchbarOnKeyboardState = useRecoilValue(searchbarOnKeyboardAtom);

  const [isCourseMenu, setIsCourseMenus] = useState<boolean>(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 150 && !searchbarOnKeyboardState.expanded) {
      setSearchbarState({ expanded: false });
    } else if (window.scrollY === 0) {
      isLanding && setSearchbarState({ expanded: true });
    }
  }, [isLanding, searchbarOnKeyboardState, setSearchbarState]);

  useEffect(() => {
    setIsCourseMenus(
      (window.location.href.indexOf('/course/') > -1 &&
        !(window.location.href.indexOf('/preview/') > -1)) ||
        (window.location.href.indexOf('/venue/create-venue') > -1 &&
          !(window.location.href.indexOf('/preview/') > -1))
    );
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (isLanding) {
      setSearchbarState({ expanded: true });
    } else {
      setSearchbarState({ expanded: false });
    }
  }, [isLanding, setSearchbarState]);

  // const handleScroll = useCallback(() => {
  //   const position = window.pageYOffset;
  //   if (position - scrollPosition > 20) {
  //     setSearchbarState({ expanded: false });
  //   } else if (position - scrollPosition < -100) {
  //     setSearchbarState({ expanded: true });
  //   }

  //   setSrollPosition(position);
  // }, [scrollPosition, setSearchbarState]);

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [handleScroll]);

  return (
    <RootStyle>
      <PotioneerAppBarV2
        isLayerOnePage={isLayerOnePage ?? false}
        hideSearch={hideSearch ?? false}
        isCourseMenu={isCourseMenu}
      />
      <MainStyle
        searchbarexpanded={searchbarState.expanded ? 1 : 0}
        hidesearchbar={hideSearch ? 1 : isLayerOnePage ? 0 : 1}
        iscoursemenu={isCourseMenu}
      >
        {children}
        {footer && <PotioneerFooter />}
      </MainStyle>
    </RootStyle>
  );
};

export default WithNavbarLayout;
