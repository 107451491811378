import NextLink from 'next/link';
import { ReactNode, useRef, useState, forwardRef, ReactElement, Ref, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography, Stack, Avatar, Icon, Chip } from '@mui/material';
import MenuPopover from '@components/MenuPopover';
import { IconButtonAnimate } from '@components/animate';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { authState } from '@atoms/auth';

import { logout } from '@services/auth';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import { Profile } from '@typings/profile';
import { profileState } from '@atoms/profile';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { resize_image } from '@utils/image';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// ----------------------------------------------------------------------

interface Menu {
  id: string;
  label: string;
  icon: string;
  linkTo: string;
  display?: boolean;
  children?: ReactNode;
}

const MENU_OPTIONS: Menu[] = [
  {
    id: 'Menu_MyProfile',
    label: 'My Profile ',
    icon: 'https://images.potioneer.com/images-landing/account_T304MtDmP.webp',
    linkTo: '/profile/0',
    display: true,
  },
  {
    id: 'Menu_Mybooking',
    label: 'My Bookings',
    icon: 'https://images.potioneer.com/images-landing/my-booking_X4tU_xu7E.webp',
    linkTo: '/booking',
    display: true,
  },
  {
    id: 'Menu_PTNcredit',
    label: 'Potioneer Credit',
    // icon: 'https://images.potioneer.com/Icon_MinimalsCC/PNG/ic_animate_MDUEZUs8Ey.png',
    icon: 'https://images.potioneer.com/Icon_MinimalsCC/SVG/ic_animate_w5qepv0C6.svg',
    linkTo: '/potioneer-credit',
    display: true,
  },
  {
    id: 'Menu_Account',
    label: 'Account Setting',
    icon: 'https://images.potioneer.com/images-landing/manage-bookings_sc0-T2abz.webp',
    linkTo: '/account/profile',
    display: true,
  },
  // {
  //   label: 'Refer a friend',
  //   icon: 'https://images.potioneer.com/images-landing/refer-a-friend_IDRjmjPO3.webp',
  //   linkTo: '/refer-a-friend',
  //   display: true,
  //   children: (
  //     <Stack direction="row" spacing={1}>
  //       <Typography variant="body2">Refer a friend</Typography>
  //       <Chip label="Beta" color="info" size="small" />
  //     </Stack>
  //   ),
  // },
];

const MENU_OPTIONS_CHEF: Menu[] = [
  {
    id: 'Menu_MyCourseMenu',
    label: 'My Course Menu',

    icon: 'https://images.potioneer.com/Icon_MinimalsCC/lucide_chef-hat_TXZEGRWsv.svg',
    linkTo: '/profile/0',
    display: false,
  },
  {
    id: 'Menu_Mngbooking',
    label: 'Manage Bookings',
    icon: 'https://images.potioneer.com/Icon_MinimalsCC/SVG/Property_1_ic_calendar_b_Y55uxeo8jq.svg',
    linkTo: '/manage-booking',
    display: false,
  },
  {
    id: 'Menu_RevenueCh',
    label: 'Revenue Dashboard',
    icon: 'https://images.potioneer.com/images-landing/revenue-dashboard_vSwY6UPGVd.webp',
    linkTo: '/revenue',
    display: false,
  },
];

const MENU_OPTIONS_VENUE: Menu[] = [
  {
    id: 'Menu_Venuedashboard',
    label: 'Venue Dashboard',
    icon: 'https://images.potioneer.com/Icon_MinimalsCC/SVG/Property_1_ic_lo_venue_bFt4-d_7F.svg',
    linkTo: '/venue/1',
    display: false,
  },
  {
    id: 'Menu_MngListing',
    label: 'Manage Listing',
    icon: 'https://images.potioneer.com/Icon_MinimalsCC/SVG/Property_1_ic_job_Kg8hDOm4uX.svg',
    linkTo: '/venue/manage-venue',
    display: false,
  },
  {
    id: 'Menu_RevenueVO',
    label: 'Revenue Dashboard',
    icon: 'https://images.potioneer.com/images-landing/revenue-dashboard_vSwY6UPGVd.webp',
    linkTo: '/venue/dashboard',
    display: false,
  },
];

// ----------------------------------------------------------------------

interface UserPopOverProps {
  id: string;
  profile: Profile;
  isCourseMenu?: boolean;
}

export default function UserPopOver({ id, profile, isCourseMenu }: UserPopOverProps) {
  const anchorRef = useRef(null);

  const router = useRouter();

  const { firstName, lastName, email, imageProfile, isChef, isVenueOwner } = profile;

  const setAuth = useSetRecoilState(authState);

  const [open, setOpen] = useState(false);

  const resetProfile = useResetRecoilState(profileState);

  const [showChefMenu, setShowChefMenu] = useState(true);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [onClickMenu, setOnClickMenu] = useState<string>('');
  const [isLinkTo, setIsLinkTo] = useState<string>('');

  const onReasonSubmit = () => {
    onClose();
    if (onClickMenu === 'logout') handleLogout();
    else router.push(isLinkTo);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    setAuth({ auth: false, loading: false });
    resetProfile();
    logout();
    handleClose();

    const url = new URL(window.location.href);

    if (url?.pathname !== '/') {
      router.push('/');
    } else {
      // window.location.reload();
    }
  };

  const stringAvatar = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
      const name = `${firstName[0]}${lastName[0]}`;
      return {
        children: name,
      };
    } else {
      return {
        children: firstName[0],
      };
    }
  };

  useEffect(() => {
    const showChefMenu = window.localStorage.getItem('showChefMenu');
    if (isChef && !isVenueOwner) {
      setShowChefMenu(true);
    } else if (!isChef && isVenueOwner) {
      setShowChefMenu(false);
    } else {
      setShowChefMenu(showChefMenu === 'true');
    }
  }, [isChef, isVenueOwner]);

  return (
    <>
      <IconButtonAnimate
        id={id}
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {imageProfile?.imageUrl ? (
          <Avatar
            src={resize_image({
              url: imageProfile?.imageUrl,
              width: 40,
              height: 40,
            })}
            alt="profile_image"
          />
        ) : (
          <Avatar {...stringAvatar(firstName, lastName)} />
        )}
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 235 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {firstName} {lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider />
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option, idx) => {
            if (!option.display && !isChef) {
              return;
            }
            return (
              <>
                {isCourseMenu ? (
                  <MenuItem
                    id={option.id}
                    key={'MENU_OPTIONS_' + idx}
                    onClick={() => {
                      setIsOpen(true);
                      setIsLinkTo(option.linkTo);
                    }}
                    sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
                  >
                    <img
                      src={resize_image({
                        url: option.icon,
                        width: 24,
                        height: 24,
                      })}
                      alt="ic_person"
                      style={{ width: 24, height: 24, marginRight: 16 }}
                    />
                    {option.children ?? option.label}
                  </MenuItem>
                ) : (
                  <NextLink key={'MENU_OPTIONS_' + idx} href={option.linkTo} passHref>
                    <MenuItem
                      id={option.id}
                      onClick={handleClose}
                      sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
                    >
                      <img
                        src={resize_image({
                          url: option.icon,
                          width: 24,
                          height: 24,
                        })}
                        alt="ic_person"
                        style={{ width: 24, height: 24, marginRight: 16 }}
                      />
                      {option.children ?? option.label}
                    </MenuItem>
                  </NextLink>
                )}
              </>
            );
          })}
        </Stack>

        {isChef && showChefMenu && (
          <>
            <Divider />
            <Stack spacing={0.5} sx={{ p: 1 }}>
              {MENU_OPTIONS_CHEF.map((option, idx) => {
                if (!option.display && !isChef) {
                  return;
                }
                return (
                  <>
                    {isCourseMenu ? (
                      <MenuItem
                        id={option.id}
                        key={'MENU_OPTIONS_CHEF_' + idx}
                        onClick={() => {
                          setIsOpen(true);
                          setIsLinkTo(option.linkTo);
                        }}
                        sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
                      >
                        <img
                          src={resize_image({
                            url: option.icon,
                            width: 24,
                            height: 24,
                          })}
                          alt="ic_person"
                          style={{ width: 24, height: 24, marginRight: 16 }}
                        />
                        {option.children ?? option.label}
                      </MenuItem>
                    ) : (
                      <NextLink key={'MENU_OPTIONS_CHEF_' + idx} href={option.linkTo} passHref>
                        <MenuItem
                          id={option.id}
                          onClick={handleClose}
                          sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
                        >
                          <img
                            src={resize_image({
                              url: option.icon,
                              width: 24,
                              height: 24,
                            })}
                            alt="ic_person"
                            style={{ width: 24, height: 24, marginRight: 16 }}
                          />
                          {option.children ?? option.label}
                        </MenuItem>
                      </NextLink>
                    )}
                  </>
                );
              })}
            </Stack>
          </>
        )}
        {isVenueOwner && !showChefMenu && (
          <>
            <Divider />
            <Stack spacing={0.5} sx={{ p: 1 }}>
              {MENU_OPTIONS_VENUE.map((option, idx) => {
                return (
                  <NextLink key={'MENU_OPTIONS_VENUE_' + idx} href={option.linkTo} passHref>
                    <MenuItem
                      id={option.id}
                      onClick={handleClose}
                      sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
                    >
                      <img
                        src={resize_image({
                          url: option.icon,
                          width: 24,
                          height: 24,
                        })}
                        alt="ic_person"
                        style={{ width: 24, height: 24, marginRight: 16 }}
                      />
                      {option.children ?? option.label}
                    </MenuItem>
                  </NextLink>
                );
              })}
            </Stack>
          </>
        )}
        <Divider />
        {isChef && isVenueOwner && (
          <Stack spacing={0.5} sx={{ p: 1 }}>
            <MenuItem
              id="Menu_Switchroll"
              onClick={() => {
                if (!showChefMenu) {
                  window.localStorage.setItem('showChefMenu', 'true');
                  router.push('/profile/0');
                } else {
                  window.localStorage.removeItem('showChefMenu');
                  router.push('/venue/1');
                }
                setShowChefMenu(!showChefMenu);
              }}
              sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
            >
              <img
                src={resize_image({
                  url: 'https://images.potioneer.com/images-landing/refer-a-friend_IDRjmjPO3.webp',
                  width: 24,
                  height: 24,
                })}
                alt="ic_person"
                style={{ width: 24, height: 24, marginRight: 16 }}
              />
              {showChefMenu ? 'Venue Partner' : 'Chef'}? click
            </MenuItem>
          </Stack>
        )}
        {!isChef && !isVenueOwner && (
          <Stack spacing={0.5} sx={{ p: 1 }}>
            <NextLink href={'/become-venue'} passHref>
              <MenuItem
                id="Menu_JoinasVO"
                sx={{ typography: 'body2', py: 1, px: 2, borderRadius: 1 }}
              >
                <img
                  src={'https://images.potioneer.com/images-landing/refer-a-friend_IDRjmjPO3.webp'}
                  alt="ic_person"
                  style={{ width: 24, height: 24, marginRight: 16 }}
                />
                Become Venue Partner
              </MenuItem>
            </NextLink>
          </Stack>
        )}

        <Button
          id="Menu_Logout"
          onClick={() => {
            if (isCourseMenu) {
              setIsOpen(true);
              setOnClickMenu('logout');
            } else handleLogout();
          }}
          variant="outlined"
          sx={{
            typography: 'subtitle1',
            py: 1,
            px: 8,
            m: 2,
            borderRadius: 1,
            justifyContent: 'center',
          }}
        >
          Logout
        </Button>
      </MenuPopover>

      <Dialog open={isOpen} onClose={onClose} TransitionComponent={Transition}>
        <DialogTitle>
          <Box py={3.5}>
            <Typography variant="h6">Leave this page?</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            You have unsaved changes that will be lost if you decide to continue
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onReasonSubmit}>
            {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
              <span>Accept</span>
              <CheckCircleRoundedIcon sx={{ fontSize: 18 }} />
            </Stack> */}
            Leave without Saving
          </Button>
          <Button variant="contained" onClick={onClose}>
            Stay on Page
          </Button>
        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Dialog>
    </>
  );
}
