import { ReactElement, FC, memo } from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';

interface CustomChipProps extends ChipProps {
  isSelected?: boolean;
}

const ChipWrap: FC<CustomChipProps> = ({ isSelected, ...other }): ReactElement => {
  return (
    <Chip
      sx={{
        '&.actived': {
          backgroundColor: 'primary.main',
          color: 'white',
        },
        ...other.sx,
      }}
      className={isSelected ? 'actived' : ''}
      deleteIcon={
        isSelected ? (
          <svg
            style={{ right: 2, position: 'relative' }}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C9.98912 0.5 11.8968 1.29018 13.3033 2.6967C14.7098 4.10322 15.5 6.01088 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8ZM10.2543 9.5C10.2543 9.30002 10.1745 9.10833 10.0325 8.9675L9.0575 8L10.0325 7.0325C10.3266 6.73841 10.3266 6.26159 10.0325 5.9675C9.73841 5.67341 9.26159 5.67341 8.9675 5.9675L8 6.9425L7.0325 5.9675C6.73841 5.67341 6.26159 5.67341 5.9675 5.9675C5.67341 6.26159 5.67341 6.73841 5.9675 7.0325L6.9425 8L5.9675 8.9675C5.82552 9.10833 5.74565 9.30002 5.74565 9.5C5.74565 9.69998 5.82552 9.89167 5.9675 10.0325C6.10833 10.1745 6.30002 10.2543 6.5 10.2543C6.69998 10.2543 6.89167 10.1745 7.0325 10.0325L8 9.0575L8.9675 10.0325C9.10833 10.1745 9.30002 10.2543 9.5 10.2543C9.69998 10.2543 9.89167 10.1745 10.0325 10.0325C10.1745 9.89167 10.2543 9.69998 10.2543 9.5Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            style={{ top: 2, position: 'relative' }}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 5.25H6.75V0.75C6.75 0.335786 6.41421 0 6 0C5.58579 0 5.25 0.335786 5.25 0.75V5.25H0.75C0.335786 5.25 0 5.58579 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.25V11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25V6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58579 11.6642 5.25 11.25 5.25Z"
              fill="#BCC0C5"
            />
          </svg>
        )
      }
      {...other}
    />
  );
};

export default memo(ChipWrap);
