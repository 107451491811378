import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useEffect, useState } from 'react';
import AppleSignInButton from './AppleSignInButton';
import FacebookSignInButton from './FacebookSignInButton';
import GoogleSignInButton from './GoogleSignInButton';
import { login, register } from 'src/services/auth';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import Image from '@components/Image';
import { resize_image } from '@utils/image';
import Link from 'next/link';

interface SignInSignUpProps {
  onSignInSuccess: () => void;
  onSignUpSuccess: (email: string) => void;
  onForgotPassword: () => void;
  isOpen: boolean;
  auth: boolean;
}
interface SignUpForm {
  mode: 'sign-up' | 'sign-in';
  email: string;
  password: string;
  isAllowDisclaimer: boolean;
  firstName: string;
  lastName: string;
}

const schema = yup
  .object({
    mode: yup.string(),
    email: yup
      .string()
      .required('Please enter the required fields.')
      .email('Please user the email must be a valid email'),
    password: yup.string().required('Please enter the required fields.'),
    isAllowDisclaimer: yup.bool().when('mode', {
      is: 'sign-up',
      then: yup.bool().required().isTrue('Please agree the terms and conditions before register.'),
    }),
    firstName: yup.string().when('mode', {
      is: 'sign-up',
      then: yup.string().required().label('first name'),
    }),
    lastName: yup.string().when('mode', {
      is: 'sign-up',
      then: yup.string().required().label('last name'),
    }),
  })
  .required();

const SignInSignUp: FunctionComponent<SignInSignUpProps> = ({
  onSignUpSuccess,
  onSignInSuccess,
  onForgotPassword,
  isOpen,
  auth,
}) => {
  const [isSignIn, setIsSignIn] = useState(true);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const [isTcError, setIsTcError] = useState<boolean>(false);
  const [messages, setMessage] = useState<string>('');

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<SignUpForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      isAllowDisclaimer: false,
      mode: 'sign-in',
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      setIsTcError(false);
    }
    if (!auth) {
      setIsSignIn(true);
    }
  }, [isOpen, reset, auth, setIsSignIn]);

  const onSubmit = (data: SignUpForm) => {
    const { email, password } = data;

    if (isSignIn) {
      login(email, password).then(({ code }) => {
        if (code !== '200') {
          setMessage(
            'Email address or password is either incorrect or not register with Potioneer'
          );
          setIsTcError(true);
          return;
        }

        onSignInSuccess();

        setTimeout(() => {
          setIsSignIn(false);
          reset();
        }, 1000);
      });
    } else {
      if (password.length >= 6 && password.length <= 20) {
        const redirectUrl = window.location.href ?? '';

        register(data, redirectUrl).then(({ code, message }) => {
          if (code !== '200') {
            setMessage(message);
            setIsTcError(true);
            return;
          }

          onSignUpSuccess(email);

          setTimeout(() => {
            setIsSignIn(false);
            reset();
          }, 1000);
        });
      } else {
        setMessage('Password must be between 6 to 20 characters');
        setIsTcError(true);
      }
    }
  };

  useEffect(() => {
    if (errors.isAllowDisclaimer) {
      setMessage(errors.isAllowDisclaimer.message || '');
      setIsTcError(true);
      return;
    }
    setMessage('');
    setIsTcError(false);
  }, [errors.isAllowDisclaimer]);

  return (
    <Container maxWidth="xs">
      <Grid container justifyContent="center" paddingTop={3}>
        <Grid item>
          <Image
            src={resize_image({
              url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
              width: 48,
              height: 48,
            })}
            alt="potioneer logo"
            disabledEffect
            sx={{ width: 48, height: 48 }}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" paddingY={2}>
        <Grid item>
          <Typography variant="h6" gutterBottom component="h1">
            Welcome to Potioneer
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <GoogleSignInButton onSignInSuccess={onSignInSuccess} />
        </Grid>
        {/* <Grid item>
          <AppleSignInButton />
        </Grid> */}
        {isOpen && (
          <Grid item>
            <FacebookSignInButton isSignIn={isSignIn} onSignInSuccess={onSignInSuccess} />
          </Grid>
        )}
      </Grid>
      <Box paddingTop={3} paddingBottom={(theme) => theme.spacing(1)}>
        <Divider style={{ color: '#637381', fontWeight: 600, fontSize: 14 }}>OR</Divider>
      </Box>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} width="100%">
        <Stack spacing={2}>
          {!isSignIn && (
            <>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      id="first_name"
                      label="First name"
                      {...field}
                      error={!!errors.firstName?.message}
                      helperText={errors.firstName?.message}
                    />
                  );
                }}
              />
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      fullWidth
                      id="last_name"
                      label="Last name"
                      {...field}
                      error={!!errors.lastName?.message}
                      helperText={errors.lastName?.message}
                    />
                  );
                }}
              />
            </>
          )}

          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  autoComplete="email"
                  {...field}
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                />
              );
            }}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => {
              return (
                <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="password"
                    label="Password"
                    fullWidth
                    type={isShowPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShowPassword((value) => !value)}
                          edge="end"
                        >
                          {isShowPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                    error={!!errors.password?.message}
                  />
                  <FormHelperText error>{errors.password?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
        {!isSignIn && (
          <Box paddingY={(theme) => theme.spacing(1)}>
            <Controller
              name="isAllowDisclaimer"
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox color="primary" {...field} />}
                    label={
                      <Typography variant="body2">
                        I agree to the{' '}
                        <Link href="/terms-and-conditions?section=terms-and-conditions">
                          <a target="_blank" style={{ color: '#212B36' }}>
                            Terms of Service Potioneer User Terms
                          </a>
                        </Link>{' '}
                        and{' '}
                        <Link href="/terms-and-conditions?section=privacy-policy">
                          <a target="_blank" style={{ color: '#212B36' }}>
                            Privacy Policy
                          </a>
                        </Link>
                      </Typography>
                    }
                  />
                );
              }}
            />
          </Box>
        )}
        <Button
          id={isSignIn ? 'Register_login' : 'Register'}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          size="large"
        >
          {isSignIn ? 'Log In' : 'Register'}
        </Button>
        <Box paddingTop={1} paddingBottom={3}>
          {isSignIn && (
            <Grid container justifyContent="center" pb={2}>
              <Grid item>
                <Typography
                  id="Register_forgotpassword"
                  variant="button"
                  onClick={onForgotPassword}
                  sx={{ cursor: 'pointer' }}
                >
                  Forgot Password
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container justifyContent="center">
            <Grid item>
              {isSignIn ? (
                <span style={{ color: '#373737' }}>
                  Don’t have an account?{' '}
                  <span
                    id="Register_signup"
                    color="#373737"
                    style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => {
                      setIsSignIn(false);
                      setValue('mode', 'sign-up');
                    }}
                  >
                    Sign up
                  </span>
                </span>
              ) : (
                <span color="#373737">
                  Already have an account?{' '}
                  <span
                    id="Register_switchtologin"
                    color="#373737"
                    style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => {
                      setIsSignIn(true);
                      setValue('firstName', '');
                      setValue('lastName', '');
                      setValue('isAllowDisclaimer', false);
                      clearErrors();
                      setValue('mode', 'sign-in');
                    }}
                  >
                    Log in
                  </span>
                </span>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={isTcError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ zIndex: 1 }}
      >
        <Alert severity="error" onClose={() => setIsTcError(false)}>
          {messages}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignInSignUp;
