import Modal from '@components/Modal';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ChefName from './ChefName';
import Container from '@mui/material/Container';
import CuisineTypes from './CuisineTypes';
import ChefLocation from './ChefLocation';
import ChefAbout from './ChefAbout';
import ChefLanguages from './ChefLanguages';
// import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { authState, chefRegisteredState } from '@atoms/auth';
import { register_chef } from 'src/services/chef';
import { get_access_token } from 'src/services/auth';
import TakeATour from './TakeATour';
import { get_current_profile } from '@services/profile';
import { get_province, Province } from '@services/province';
import { CuisineType, get_cuisine_type } from '@services/cuisine_type';
import { get_languages, Languages } from 'src/services/languages';
import ChefAlreadyRegistered from './ChefAlreadyRegistered';
import { useRouter } from 'next/router';

export interface CreateChefForm {
  name: string;
  languageSpeak: number[];
  typeOfCuisineIds: number[];
  titleLocation: string;
  typeOfLocation: string;
  baseId: number | string;
  // provinceId: number | null;
  about: string;
  education: string;
}

interface CreateChefModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateChefModal: FunctionComponent<CreateChefModalProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const router = useRouter();

  const isChefRegistered = useRecoilValue(chefRegisteredState);

  const [state, setState] = useState<string>('chef_name');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const auth = useRecoilValue(authState);

  const [provinces, setProvince] = useState<Province[]>([]);
  const [cuisineTypes, setCuisineTypes] = useState<CuisineType[]>([]);
  const [languages, setLanguages] = useState<Languages[]>([]);

  const [values, setValues] = useState<CreateChefForm>({
    name: '',
    languageSpeak: [],
    typeOfCuisineIds: [],
    titleLocation: '',
    typeOfLocation: '',
    baseId: '',
    // provinceId: null,
    about: '',
    education: '',
  });

  useEffect(() => {
    if (isOpen) {
      get_province().then((res) => setProvince(res?.data));

      get_cuisine_type().then((cts) => {
        setCuisineTypes(cts?.data?.filter((ct) => ct.isActive));
      });

      get_languages().then((response) => {
        setLanguages(response.data);
      });
    }

    setState(isChefRegistered ? 'chef_registered' : 'chef_name');
  }, [isOpen, isChefRegistered]);

  const onModalClose = () => {
    onClose();
    setState('reset');

    router.push('/');

    setTimeout(() => {
      setValues({
        name: '',
        languageSpeak: [],
        typeOfCuisineIds: [],
        titleLocation: '',
        typeOfLocation: '',
        baseId: '',
        // provinceId: null,
        about: '',
        education: '',
      });
      setState(isChefRegistered ? 'chef_registered' : 'chef_name');

      window.location.reload();
    }, 500);
  };

  const content = useMemo(() => {
    const onSubmit = async (submitdata: CreateChefForm) => {
      if (auth.auth) {
        const token = await get_access_token();

        register_chef(token, submitdata).then(({ code, message }) => {
          if (code !== '200') {
            // alert(message);
            return;
          }

          get_current_profile(token).then(({ code, message, data }) => {
            if (code !== '200') {
              // alert(message);
              return;
            }

            setState('take_a_tour');
          });
        });
      }
    };

    switch (state) {
      case 'chef_name':
        return (
          <ChefName
            isMobile={isMobile}
            onNext={() => setState('chef_languages')}
            formValue={values}
            onFormSubmit={(name: string) => {
              setValues((prev) => ({ ...prev, name }));
            }}
          />
        );
      case 'chef_languages':
        return (
          <ChefLanguages
            formValue={values}
            languageList={languages}
            isMobile={isMobile}
            onNext={() => setState('cuisine')}
            onBack={() => setState('chef_name')}
            onFormSubmit={(languages: number[]) => {
              setValues((prev) => ({
                ...prev,
                languageSpeak: languages,
              }));
            }}
          />
        );
      case 'cuisine':
        return (
          <CuisineTypes
            formValue={values}
            cuisineTypes={cuisineTypes}
            isMobile={isMobile}
            onNext={() => setState('location')}
            onBack={() => setState('chef_languages')}
            onFormSubmit={(cuisineids: number[]) => {
              setValues((prev) => ({
                ...prev,
                typeOfCuisineIds: cuisineids,
              }));
            }}
          />
        );
      case 'location':
        return (
          <ChefLocation
            provinces={provinces}
            formValue={values}
            isMobile={isMobile}
            onNext={() => setState('about')}
            onBack={() => setState('cuisine')}
            onFormSubmit={(title: string, type: string, baseId: number) => {
              setValues((prev) => ({
                ...prev,
                titleLocation: title,
                typeOfLocation: type,
                baseId: baseId,
              }));
            }}
          />
        );
      case 'about':
        return (
          <ChefAbout
            formValue={values}
            isMobile={isMobile}
            onBack={(about: string, education: string) => {
              setState('location');
              setValues((prev) => ({ ...prev, about, education }));
            }}
            onFormSubmit={(about: string, education: string) => {
              setValues((prev) => {
                const data = { ...prev, about, education };
                onSubmit(data);
                return data;
              });
            }}
          />
        );
      case 'take_a_tour':
        return <TakeATour chefname={values.name} isMobile={isMobile} />;
      case 'chef_registered':
        return <ChefAlreadyRegistered />;
      default:
        return <></>;
    }
  }, [state, auth.auth, isMobile, values, languages, cuisineTypes, provinces]);

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <Container>
        <Box width={isMobile ? '100%' : '550px'} px={{ md: 9, sx: 1 }} pt={{ md: 3, sx: 0 }}>
          {content}
        </Box>
      </Container>
    </Modal>
  );
};

export default CreateChefModal;
