import { post, get, put, deletes } from '@utils/fetch';
import {
  RegisterChef,
  Chef,
  ChefCourses,
  ChefAvailableCourses,
  ChefTopReviews,
  ChefGallery,
  ChefVenueRequests,
} from 'src/types/chef';
import type { Location } from '@typings/course';
import { Profile } from '@typings/profile';

export function register_chef(token: string, data: RegisterChef) {
  return post(`${process.env.NEXT_PUBLIC_API_URL}/register/chef`, data, {
    Authorization: `Bearer ${token}`,
  });
}

// export async function get_chef_by_id(chefId: string) {
//   return get<Profile[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/${chefId}`);
// }

export async function get_chef_by_id(userId: string) {
  return get<Profile[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/profile/${userId}`);
}

export async function get_chef_course(chefId: string) {
  return get<ChefCourses[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/${chefId}/course`);
}

export async function get_chef_course_available(chefId: string, fromDate: Date, toDate: Date) {
  return get<ChefAvailableCourses[]>(
    `${
      process.env.NEXT_PUBLIC_API_URL
    }/chef/${chefId}/course-available?from=${fromDate.toISOString()}&to=${toDate.toISOString()}`
  );
}

export async function get_chef_course_available_v2(
  chefId: string,
  fromDate: string,
  toDate: string
) {
  return get<ChefAvailableCourses[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/chef/${chefId}/course-available?from=${fromDate}&to=${toDate}`
  );
}

export async function get_chef_top_review(chefId: string) {
  return get<ChefTopReviews[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/${chefId}/top-review`);
}

export function get_chef(token: string) {
  return get<Profile[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef`, {
    Authorization: `Bearer ${token}`,
  });
}

export function update_chef(token: string, data: RegisterChef) {
  return put<Profile[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function get_chef_location(token: string) {
  return get<Location[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/location`, {
    Authorization: `Bearer ${token}`,
  });
}

export function get_chef_course_list_me(token: string) {
  return get<ChefCourses[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/course?page=1&limit=999`, {
    Authorization: `Bearer ${token}`,
  });
}

export function get_chef_gallery_me(token: string, limit: number, next?: string) {
  return get<ChefGallery[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/chef/gallery?limit=${limit}${next ? `&next=${next}` : ''}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function get_chef_gallery_by_id(chefId: string, limit: number, next?: string) {
  return get<ChefGallery[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/chef/${chefId}/gallery?limit=${limit}${
      next ? `&next=${next}` : ''
    }`
  );
}

export function get_chef_venue_requests(token: string) {
  return get<[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/venue-requests`, {
    Authorization: `Bearer ${token}`,
  });
}

export function chef_venue_requests(token: string, data: ChefVenueRequests) {
  return post(`${process.env.NEXT_PUBLIC_API_URL}/chef/venue-requests`, data, {
    Authorization: `Bearer ${token}`,
  });
}

export function chef_delete_venue_requests(token: string, venueId: number) {
  return deletes(`${process.env.NEXT_PUBLIC_API_URL}/chef/venue-requests/${venueId}`, {
    Authorization: `Bearer ${token}`,
  });
}
