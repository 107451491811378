import { ShortProfile } from '@services/profile';
import { atom } from 'recoil';
import { Profile } from '@typings/profile';

export const profileState = atom<Profile>({
  key: 'PROFILE_STATE',
  default: {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    imageProfile: {
      id: 0,
      imageUrl: '',
      thumbnailUrl: '',
    },
    about: '',
    // country: null,
    // province: null,
    languageSpeak: [],
    allergies: [],
    remark: '',
    type: '',
    isActive: false,
    isChef: false,
    isVenueOwner: false,
    status: '',
    isVerifyPhoneNumber: false,
    isAllowDisclaimer: false,
    numberOfVaccination: 0,
    phoneNumber: '',
    countryCode: '',
    chef: {
      id: 0,
      name: '',
      phoneNumber: '',
      imageCover: {
        id: 0,
        imageUrl: '',
        thumbnailUrl: '',
      },
      countryCode: null,
    },
    venueOwner: {
      id: 0,
      email: '',
      name: '',
      phoneNumber: '',
      isPhoneNumberVerified: false,
    },
  },
});

interface ShortProfileState {
  profiles: Record<number, ShortProfile>;
}

export const shortProfileState = atom<ShortProfileState>({
  key: 'SHORT_PROFILE_STATE',
  default: {
    profiles: {},
  },
});
