import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ChangeEventHandler, FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useScreen from 'src/hooks/useScreen';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CreateChefForm } from './CreateChefModal';
import { validateName } from '@services/validation';

interface ChefNameForm {
  name: string;
}
interface ChefNameProps {
  isMobile?: boolean;
  onNext: () => void;
  formValue: CreateChefForm;
  onFormSubmit: (name: string) => void;
}

const chefschema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

const ChefName: FunctionComponent<ChefNameProps> = ({
  isMobile,
  onNext,
  onFormSubmit,
  formValue,
}) => {
  const { height } = useScreen(100);

  const {
    control,
    reset,
    getValues,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ChefNameForm>({
    resolver: yupResolver(chefschema),
    defaultValues: {
      name: formValue.name,
    },
  });

  const [count, setCount] = useState<number>(getValues().name.length);
  const [name, setName] = useState<string>(getValues().name);

  const onSubmit = (data: ChefNameForm) => {
    onFormSubmit(data.name);
    onNext();
  };

  useEffect(() => {
    return () => {
      console.log('unmount');
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= 30) {
      setName(event.target.value);
    }
    const check = await validateName(event.target.value, 'chef', '0');
    if (check.code !== '200') {
      setError('name', {
        type: 'manual',
        message: check.message,
      });
    } else {
      if (event.target.value.length < 30) {
        setCount(event.target.value.length);
        setName(event.target.value);
        setValue('name', event.target.value);
        setError('name', {
          type: 'manual',
          message: '',
        });
      } else {
        setError('name', {
          type: 'manual',
          message: 'Name must be less than 30 characters',
        });
      }
    }
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      height={isMobile ? height : '750px'}
      component="form"
      paddingTop={6}
      paddingBottom={{ xs: 5, md: 3 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item>
        <Box marginBottom={4}>
          <Typography variant="h4">Hi Chef, how would you</Typography>
          <Typography variant="h4" gutterBottom>
            like to be called?
          </Typography>
        </Box>

        <TextField
          value={name}
          placeholder="e.g. Chef Jamie"
          fullWidth
          onChange={onChange}
          error={!!errors.name?.message}
          helperText={errors.name?.message}
        />

        {!errors.name?.message && (
          <Grid container paddingY={0.5} alignItems="center">
            <Grid item>
              <img
                src="/icons/ic_warning_13x13.svg"
                alt="warning"
                style={{ display: 'inline', marginRight: 5, paddingTop: 10 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" color="#637381">
                {count}/30
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Button
          disabled={!!errors.name?.message}
          type="submit"
          fullWidth
          variant="contained"
          size="large"
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChefName;
