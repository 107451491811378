import Image from '@components/Image';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { get_access_token } from '@services/auth';
import { get_current_profile } from '@services/profile';
import { useSetRecoilState } from 'recoil';
import { profileState } from '@atoms/profile';
import useScreen from '@hooks/useScreen';

interface TakeATourProps {
  chefname: string;
  isMobile: boolean;
}

const TakeATour: FunctionComponent<TakeATourProps> = ({ chefname, isMobile }) => {
  const router = useRouter();

  const { height } = useScreen(100);

  const setProfile = useSetRecoilState(profileState);

  const onClick = () => {
    get_access_token().then((token) => {
      get_current_profile(token).then((res) => {
        if (res?.code !== '200') {
          // alert(res.message);
          return;
        }

        setProfile(res?.data);

        router.push('/profile/0?take_a_tour=true');
      });
    });
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      direction="column"
      paddingTop={6}
      paddingBottom={{ xs: 5, md: 3 }}
      height={isMobile ? height : '750px'}
    >
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item>
            <Image src="/images/chef-image.svg" alt="chef image" />
          </Grid>
        </Grid>
        <Box py={2}>
          <Typography variant="h4" align="center">
            Hi {chefname}!
          </Typography>
          <Typography variant="h4" align="center">
            welcome to Potioneer
          </Typography>
        </Box>
        <Box sx={{ backgroundColor: '#F4F6F8', borderRadius: 2 }} p={2}>
          <Typography variant="subtitle2">Dear {chefname}!</Typography>
          <Typography variant="body2">Welcome on-board!</Typography>
          <Box py={4}>
            <Typography variant="body2">
              We are extremely excited to be part of your amazing culinary journey.
            </Typography>
          </Box>
          <Typography variant="body2">Cheers 🎉</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button variant="contained" size="large" fullWidth onClick={onClick}>
          Take A Tour
        </Button>
      </Grid>
    </Grid>
  );
};

export default TakeATour;
