import React, { ReactElement, FC, memo } from 'react';
import Box from '@mui/material/Box';
import { resize_image } from '@utils/image';

const Loading: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-50px',
        marginLeft: '-50px',
        width: '100px',
        height: '100px',
        textAlign: 'center',
        '> img': {
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        '> span': {
          fontWeight: 'bold',
        },
      }}
    >
      <img
        alt="potioneer"
        src={resize_image({
          url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
          width: 50,
          height: 50,
        })}
        width="50"
      />
      <span>Loading...</span>
    </Box>
  );
};

export default memo(Loading);
