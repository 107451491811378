import { useEffect, useState } from 'react';

interface Screen {
  height: string;
  width: string;
}

export default function useScreen(height = 100, width = 100) {
  const [screen, setScreen] = useState<Screen>({
    height: `${height}vh`,
    width: `${width}vw`,
  });

  useEffect(() => {
    const onresize = () => {
      setScreen({
        height: `${(window.innerHeight * height) / 100}px`,
        width: `${(window.innerWidth * width) / 100}px`,
      });
    };

    onresize();

    window.addEventListener('resize', onresize);

    return () => window.removeEventListener('resize', onresize);
  }, [height, width]);

  return screen;
}
