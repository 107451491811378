import { FunctionComponent } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import GoogleLogin from 'react-google-login';
import { login_with_google } from 'src/services/auth';
import { useSetRecoilState } from 'recoil';
import { authState } from 'src/recoil/atom/auth';

const GoogleSignIn = styled(Button)({
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
  justifyContent: 'start',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: 15,
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
  },
});

interface GoogleSignInButtonProp {
  onSignInSuccess?: () => void;
}

const GoogleSignInButton: FunctionComponent<GoogleSignInButtonProp> = ({ onSignInSuccess }) => {
  const setAuth = useSetRecoilState(authState);

  const onSuccess = async (res: any) => {
    const { accessToken } = res;

    const redirectUrl = window.location.href ?? '';

    const { code, message, data } = await login_with_google('Bearer', accessToken, redirectUrl);

    if (code === '200' && data.length > 0) {
      setAuth({ auth: true, loading: false });
      if (onSignInSuccess) onSignInSuccess();
    } else {
      // alert(message);
    }
  };

  const onFailure = (res: any) => {
    console.error(res);
  };

  return (
    <GoogleLogin
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''}
      render={(props) => (
        <GoogleSignIn
          variant="contained"
          size="large"
          startIcon={
            <img src="/images/logos/google-logo.svg" alt="google logo" style={{ marginRight: 8 }} />
          }
          fullWidth
          onClick={props.onClick}
        >
          Continue with Google
        </GoogleSignIn>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default GoogleSignInButton;
