import { authState, signUpSignInModalState } from '@atoms/auth';
import { profileState } from '@atoms/profile';
import { searchbarAtom } from '@atoms/searchbar';
import { IconButtonAnimate } from '@components/animate';
import SignInModal, { State } from '@components/authentication/Invitation/SignInModal';
import SignInSignUpModal from '@components/authentication/SignInSignUpModal';
import CreateChefModal from '@components/create-chef-form/CreateChefModal';
import Iconify from '@components/Iconify';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  useCallback,
  useState,
  forwardRef,
  ReactElement,
  Ref,
  useRef,
  useEffect,
} from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import MenuPopover from '@components/MenuPopover';
import { useRecoilState, useRecoilValue } from 'recoil';
import NotificationsPopover from './dashboard/header/NotificationsPopover';
import SearchBarV3 from './SearchBarV3';
import UserPopOver from './UserPopOver';
import { DASHBOARD_NAVBAR_WIDTH } from '../config';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Divider from '@mui/material/Divider';
import { resize_image } from '@utils/image';
// import { validateTokenLogin } from '@services/auth';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PageLinkDesktop {
  id: string;
  Page: string;
  Link: string;
  DesktopHide?: boolean;
}

interface PageLinkMobile {
  id: string;
  Page: string;
  Link: string;
  children?: PageLinkDesktop[];
}

const pageLinksDesktop: PageLinkDesktop[] = [
  {
    id: 'Navbar_forchef',
    Page: 'For chef',
    Link: '/for-chef',
  },
  {
    id: 'Navbar_forVO',
    Page: 'For Venue Partnership',
    Link: '/become-venue',
  },
  // {
  //   Page: 'For Corporate',
  //   Link: '/for-corporate',
  //   DesktopHide: true,
  // },
];

const pageLinksMobile: PageLinkMobile[] = [
  {
    id: 'Navbar_partner',
    Page: 'Be our partner',
    Link: '#',
    children: [
      {
        id: 'Navbar_forchef',
        Page: 'For chef',
        Link: '/for-chef',
      },
      {
        id: 'Navbar_forVO',
        Page: 'For Venue Partnership',
        Link: '/become-venue',
      },
    ],
  },
  {
    id: 'Navbar_forcorporate',
    Page: 'For Corporate',
    Link: '/for-corporate',
  },
];

const LogoStyle = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingX: '20px',
}));

interface PotioneerAppBarV2Props {
  isLayerOnePage: boolean;
  isCourseMenu: boolean;
  hideSearch: boolean;
}

const PotioneerAppBarV2: FunctionComponent<PotioneerAppBarV2Props> = (props) => {
  const { isLayerOnePage, isCourseMenu, hideSearch } = props;
  const router = useRouter();
  const theme = useTheme();
  const { token } = router.query;
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktopCourseMenuPage = useMediaQuery(theme.breakpoints.up('lg'));

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [isOpenRegisterChefModal, setIsOpenRegisterChefModal] = useState<boolean>(false);

  const [isOpenAuthModal, setIsOpenAuthModal] = useRecoilState(signUpSignInModalState);

  const [auth, setAuth] = useRecoilState(authState);

  // const [oneTimeCheck, setOneTimeCheck] = useState<boolean>(true);

  const profile = useRecoilValue(profileState);

  const [searchbarState, setSearchbarState] = useRecoilState(searchbarAtom);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const [isOpenListMenu, setOpenListMenu] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log(oneTimeCheck, token);
  //   if (oneTimeCheck) {
  //     if (!auth.auth && token) {
  //       validateTokenLogin(token as string).then((res) => {
  //         if (res) {
  //           setAuth({ auth: true, loading: true });
  //           setOneTimeCheck(false);
  //         } else {
  //           setOneTimeCheck(false);
  //         }
  //       });
  //     }
  //   }
  // }, [auth, oneTimeCheck, setAuth, token]);

  const onReasonSubmit = () => {
    onClose();
    router.push('/');
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setOpenListMenu(false);
  };

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!auth.auth) {
        setIsOpenAuthModal({ open: true, mode: 'sign-in' });
      }
    },
    [auth.auth, setIsOpenAuthModal]
  );

  const becomeChefOnClick = useCallback(() => {
    if (!auth.auth) {
      setIsOpenAuthModal({
        open: true,
        redirect: '/?chef_register=true',
        mode: 'request-invitation',
      });
      return;
    }
    setIsOpenRegisterChefModal(true);
  }, [auth, setIsOpenAuthModal]);

  const linkToLanding = () => {
    const url = new URL(window.location.href);

    if (url?.pathname !== '/') {
      router.push('/').then(() => {
        if (!profile.isVerifyPhoneNumber) {
          window.location.reload();
        }
      });
    } else {
      // slide to top
      const element = document.getElementById('landing-top');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    }
  };

  return (
    <>
      <AppBar
        sx={{
          background: '#FCFAF7',
          paddingLeft: isCourseMenu
            ? isDesktopCourseMenuPage
              ? `${DASHBOARD_NAVBAR_WIDTH}px`
              : '36px'
            : undefined,
        }}
      >
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {isCourseMenu ? (
                  <a id="Navbar_logo" onClick={() => setIsOpen(true)}>
                    <LogoStyle>
                      <img
                        alt="potioneer"
                        src={resize_image({
                          url: 'https://images.potioneer.com/images-landing/new_logo_q2_s-SuU4.png',
                          width: 148,
                          height: 44,
                        })}
                        width={147}
                      />
                    </LogoStyle>
                  </a>
                ) : (
                  <>
                    {isDesktop ? (
                      <a id="Navbar_logo" onClick={() => linkToLanding()}>
                        <LogoStyle>
                          <img
                            alt="potioneer"
                            src={resize_image({
                              url: 'https://images.potioneer.com/images-landing/new_logo_q2_s-SuU4.png',
                              width: 148,
                              height: 44,
                            })}
                            height={44}
                            // width={147}
                          />
                        </LogoStyle>
                      </a>
                    ) : (
                      <a id="Navbar_logo" onClick={() => linkToLanding()}>
                        <LogoStyle>
                          <img
                            alt="potioneer"
                            src={resize_image({
                              url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
                              width: 48,
                              height: 48,
                            })}
                            height={48}
                            // width={48}
                          />
                        </LogoStyle>
                      </a>
                    )}
                  </>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', sm: 'flex' },
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                {!searchbarState.expanded && isLayerOnePage && !hideSearch && (
                  <Button
                    disableRipple
                    onClick={() => setSearchbarState({ expanded: true })}
                    sx={{
                      paddingRight: isDesktop ? '3%' : '0%',
                      '&:hover': {
                        background: 'transparent',
                        opacity: 0.5,
                      },
                    }}
                  >
                    <Iconify
                      icon={'eva:search-fill'}
                      color={'#212B36'}
                      width={20}
                      height={20}
                      mr={1.3}
                    />
                    {isDesktop && (
                      <Typography variant="subtitle2" color={'#212B36'}>
                        Search
                      </Typography>
                    )}
                  </Button>
                )}
                {isLayerOnePage && (
                  <>
                    <Typography
                      id="Navbar_partner"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setOpen(true)}
                      ref={anchorRef}
                      variant="subtitle2"
                    >
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={0}
                        mr={1.5}
                      >
                        <div>Be our partner</div> <ExpandMoreRoundedIcon />
                      </Stack>
                    </Typography>
                    <MenuPopover
                      open={open}
                      onClose={() => setOpen(!open)}
                      anchorEl={anchorRef.current}
                      sx={{ width: 220 }}
                    >
                      <Stack spacing={0.5} sx={{ p: 1, pt: 2 }}>
                        {pageLinksDesktop
                          .filter((page) => {
                            if (!isDesktop) return true;

                            return !page.DesktopHide;
                          })
                          .map((page, idx) => (
                            <Link
                              key={'Page_Links_Desktop_' + idx}
                              href={page.Link}
                              passHref={true}
                            >
                              <Box sx={{ pl: 3, pt: 1, pb: 1, cursor: 'pointer' }}>
                                <Typography
                                  id={page.id}
                                  variant="body2"
                                  fontWeight={400}
                                  whiteSpace={'nowrap'}
                                >
                                  {page.Page}
                                </Typography>
                              </Box>
                            </Link>
                          ))}
                      </Stack>
                    </MenuPopover>

                    <Box key={1} sx={{ paddingRight: '20px' }}>
                      <Link href={'/for-corporate'} passHref={true}>
                        <Button
                          id="Navbar_forcorporate"
                          disableRipple
                          onClick={handleCloseNavMenu}
                          sx={{
                            my: 2,
                            color: '#212B36',
                            display: 'block',
                            paddingX: 0,
                            '&:hover': {
                              background: 'transparent',
                              opacity: 0.5,
                            },
                          }}
                        >
                          <Typography variant="subtitle2" whiteSpace={'nowrap'}>
                            For corporate
                          </Typography>
                        </Button>
                      </Link>
                    </Box>
                  </>
                )}
                {!profile.isChef && (
                  <Button
                    id="Navbar_joinaschef"
                    variant="contained"
                    sx={{ mr: 4, ml: 1.5, textTransform: 'none', color: theme.palette.grey[4] }}
                    onClick={becomeChefOnClick}
                  >
                    Join as Chef
                  </Button>
                )}
                {auth.auth ? (
                  <>
                    <Box sx={{ pr: 3 }}>
                      <NotificationsPopover isCourseMenu={isCourseMenu} />
                    </Box>
                    <UserPopOver id="Navbar_avatar" profile={profile} isCourseMenu={isCourseMenu} />
                  </>
                ) : (
                  <>
                    <IconButtonAnimate
                      id="Navbar_avatar"
                      onClick={handleOpenUserMenu}
                      sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...{
                          '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                          },
                        },
                      }}
                    >
                      <Avatar
                        alt="avatar"
                        src={resize_image({
                          url: 'https://images.potioneer.com/images-landing/Avatar_zvec-4AqF.png',
                          width: 40,
                          height: 40,
                        })}
                      />
                    </IconButtonAnimate>
                  </>
                )}
              </Box>

              {/* mobile  */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', sm: 'none' },
                }}
              >
                {isCourseMenu ? (
                  <a id="Navbar_logo" href="#" onClick={() => setIsOpen(true)}>
                    <LogoStyle>
                      <img
                        alt="potioneer"
                        src={resize_image({
                          url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
                          width: 48,
                          height: 48,
                        })}
                        width={48}
                      />
                    </LogoStyle>
                  </a>
                ) : (
                  <a id="Navbar_logo" href="#" onClick={() => linkToLanding()}>
                    <LogoStyle>
                      <img
                        alt="potioneer"
                        src={resize_image({
                          url: 'https://images.potioneer.com/images-landing/PTN_LOGO_ICON-01_D4f3S4Td9.png',
                          width: 48,
                          height: 48,
                        })}
                        width={48}
                      />
                    </LogoStyle>
                  </a>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', sm: 'none' },
                  justifyContent: 'flex-end',
                }}
              >
                {!searchbarState.expanded && isLayerOnePage && (
                  <IconButton
                    disableRipple
                    onClick={() => setSearchbarState({ expanded: true })}
                    sx={{
                      '&:hover': {
                        background: 'transparent',
                        opacity: 0.5,
                      },
                    }}
                  >
                    <Iconify icon={'eva:search-fill'} color={'#212B36'} width={20} height={20} />
                  </IconButton>
                )}
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <img src="/icons/ic_menu.svg" alt="menu" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pageLinksMobile.map((pageLink, idx) => (
                    <>
                      {pageLink.children ? (
                        <>
                          <MenuItem
                            id={pageLink.id}
                            key={'Page_Links_Mobile_' + idx}
                            onClick={() => setOpenListMenu(!isOpenListMenu)}
                          >
                            <Typography textAlign="center">{pageLink.Page}</Typography>

                            <Iconify
                              icon={
                                isOpenListMenu
                                  ? 'eva:arrow-ios-upward-fill'
                                  : 'eva:arrow-ios-forward-fill'
                              }
                              sx={{ width: 16, height: 16, ml: 1 }}
                            />
                          </MenuItem>
                          {isOpenListMenu && (
                            <>
                              {pageLink.children.map((subPageLink, idx) => (
                                <Link
                                  key={'Subpage_Links_Mobile_' + idx}
                                  href={subPageLink.Link}
                                  passHref={true}
                                >
                                  <MenuItem id={subPageLink.id} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{subPageLink.Page}</Typography>
                                  </MenuItem>
                                </Link>
                              ))}
                              <Divider />
                            </>
                          )}
                        </>
                      ) : (
                        <Link key={pageLink.Page} href={pageLink.Link} passHref={true}>
                          <MenuItem id={pageLink.id} onClick={handleCloseNavMenu}>
                            <Typography textAlign="center">{pageLink.Page}</Typography>
                          </MenuItem>
                        </Link>
                      )}
                    </>
                  ))}

                  {!profile.isChef && (
                    <MenuItem>
                      <Button
                        id="Navbar_joinaschef"
                        variant="contained"
                        sx={{ px: 6, textTransform: 'none', color: theme.palette.grey[4] }}
                        onClick={() => {
                          becomeChefOnClick();
                          handleCloseNavMenu();
                        }}
                      >
                        Join as Chef
                      </Button>
                    </MenuItem>
                  )}
                </Menu>

                {/* userMenu */}
                {auth.auth && profile != undefined ? (
                  <>
                    <Box sx={{ pr: 2 }}>
                      <NotificationsPopover isCourseMenu={isCourseMenu} />
                    </Box>
                    <UserPopOver id="Navbar_avatar" profile={profile} isCourseMenu={isCourseMenu} />
                  </>
                ) : (
                  <Tooltip title="Open settings">
                    {/* userMenu */}
                    <IconButtonAnimate
                      id="Navbar_avatar"
                      onClick={handleOpenUserMenu}
                      sx={{
                        padding: 0,
                        width: 44,
                        height: 44,
                        ...{
                          '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                          },
                        },
                      }}
                    >
                      <Avatar
                        alt="avatar"
                        src={resize_image({
                          url: 'https://images.potioneer.com/images-landing/Avatar_zvec-4AqF.png',
                          width: 40,
                          height: 40,
                        })}
                      />
                    </IconButtonAnimate>
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Container>
        </Toolbar>
        {isLayerOnePage && !hideSearch && <SearchBarV3 />}
      </AppBar>

      <SignInSignUpModal
        auth={auth.auth}
        isOpen={isOpenAuthModal.open}
        // refId={isOpenAuthModal.refId}
        // mode={isOpenAuthModal.mode}
        onClose={() => {
          setIsOpenAuthModal((prev) => ({
            ...prev,
            open: false,
            redirect: undefined,
            mode: 'sign-in',
          }));
        }}
        onSignInSuccessCallback={() => {
          setIsOpenAuthModal((prev) => ({
            ...prev,
            open: false,
            redirect: undefined,
            mode: 'sign-in',
          }));

          setAuth({ auth: true, loading: false });

          if (isOpenAuthModal.redirect) {
            router.push(isOpenAuthModal.redirect);
          }
        }}
        // onOnStateChange={(mode: State) => {
        //   setIsOpenAuthModal((prev) => ({
        //     ...prev,
        //     mode: mode,
        //   }));
        // }}
      />

      {/* <SignInModal
        isOpen={isOpenAuthModal.open}
        refId={isOpenAuthModal.refId}
        mode={isOpenAuthModal.mode}
        onClose={() => {
          setIsOpenAuthModal((prev) => ({
            ...prev,
            open: false,
            redirect: undefined,
            mode: 'sign-in',
          }));
        }}
        onSignInSuccessCallback={() => {
          setIsOpenAuthModal((prev) => ({
            ...prev,
            open: false,
            redirect: undefined,
            mode: 'sign-in',
          }));

          setAuth({ auth: true, loading: false });

          if (isOpenAuthModal.redirect) {
            router.push(isOpenAuthModal.redirect);
          }
        }}
        onOnStateChange={(mode: State) => {
          setIsOpenAuthModal((prev) => ({
            ...prev,
            mode: mode,
          }));
        }}
      /> */}
      <CreateChefModal
        isOpen={isOpenRegisterChefModal}
        onClose={() => setIsOpenRegisterChefModal(false)}
      />

      <Dialog open={isOpen} onClose={onClose} TransitionComponent={Transition}>
        <DialogTitle>
          <Box py={3.5}>
            <Typography variant="h6">Leave this page?</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            You have unsaved changes that will be lost if you decide to continue
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onReasonSubmit}>
            {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
              <span>Accept</span>
              <CheckCircleRoundedIcon sx={{ fontSize: 18 }} />
            </Stack> */}
            Leave without Saving
          </Button>
          <Button variant="contained" onClick={onClose}>
            Stay on Page
          </Button>
        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Dialog>
    </>
  );
};

export default PotioneerAppBarV2;
