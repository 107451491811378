import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import useScreen from 'src/hooks/useScreen';
import { create_cuisine_type, CuisineType } from 'src/services/cuisine_type';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { CreateChefForm } from './CreateChefModal';
import Skeleton from '@mui/material/Skeleton';
import ChipWrap from '@components/course/ChipWrap';
import ErrorIcon from '@mui/icons-material/Error';
import { CourseTypeOfCuisines } from '@typings/course';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Modal from '@components/Modal';
import Container from '@mui/material/Container';

const countCuisineTypeSelected = 3;

interface CuisineTypeProps {
  formValue: CreateChefForm;
  onFormSubmit: (typeOfCuisineIds: number[]) => void;
  cuisineTypes: CuisineType[];
  isMobile?: boolean;
  onBack: () => void;
  onNext: () => void;
}

interface CuisineTypesForm {
  typeOfCuisineIds: number[];
}

function unfocus(): any {
  const tmp: HTMLInputElement = document.createElement('input');
  document.body.appendChild(tmp);
  tmp.focus();
  document.body.removeChild(tmp);
}

const cuisinetype = yup
  .object({
    typeOfCuisineIds: yup.array(yup.number()).min(1).max(3).required(),
  })
  .required();

const CuisineTypes: FunctionComponent<CuisineTypeProps> = ({
  isMobile,
  onBack,
  onNext,
  formValue,
  onFormSubmit,
  cuisineTypes: initcuisineTypes,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const { height } = useScreen(100);

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [isLoadingCuisineType, setIsLoadingCuisineType] = useState<boolean>(true);

  const [isLoadingCreateCuisineType, setIsLoadingCreateCuisineType] = useState<boolean>(false);
  const [inputCuisineType, setInputCuisineType] = useState<{ id: number; label: string }>({
    id: 0,
    label: '',
  });
  const [isShowCuisineType, setIsShowCuisineType] = useState<boolean>(true);
  const [disabledCuisineType, setDisabledCuisineType] = useState<boolean>(false);
  const [tempDisabledCuisineType, setTempDisabledCuisineType] = useState<boolean>(false);
  const [cuisineTypes, setCuisineTypes] = useState<CourseTypeOfCuisines[]>([]);
  const [tempCuisineTypes, setTempCuisineTypes] = useState<CourseTypeOfCuisines[]>([]);
  const [dropdownCuisineTypes, setDropdownCuisineTypes] = useState<CourseTypeOfCuisines[]>([]);

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<CuisineTypesForm>({
    resolver: yupResolver(cuisinetype),
    defaultValues: {
      typeOfCuisineIds: formValue.typeOfCuisineIds,
    },
  });

  const onSubmit = (data: CuisineTypesForm) => {
    onFormSubmit(data.typeOfCuisineIds);
    onNext();
  };

  const onSearchCuisineType = () => {
    setInputCuisineType({ id: 0, label: '' });
    setIsShowCuisineType(true);
    setIsOpenDialog(true);
    setTempCuisineTypes((prev) => {
      return prev.map((v) => {
        const typeOfCuisine = cuisineTypes?.filter((v) => v.isSelected)?.find((f) => f.id === v.id);
        return typeOfCuisine ? { ...typeOfCuisine, isSelected: true } : { ...v, isSelected: false };
      });
    });
  };

  const onCreateTypeOfCuisine = async () => {
    setIsLoadingCreateCuisineType(true);
    console.log(
      inputCuisineType?.label
        .substring(0, 1)
        .toUpperCase()
        .concat(inputCuisineType?.label.substring(1))
    );
    const res = await create_cuisine_type({
      name:
        inputCuisineType?.label
          .substring(0, 1)
          .toUpperCase()
          .concat(inputCuisineType?.label.substring(1)) || '',
    });
    if (res?.code !== '200') {
      setIsLoadingCreateCuisineType(false);
      // alert(res?.message || '');
      return true;
    }

    setTempCuisineTypes((prev) => {
      return !res?.data?.[0]
        ? prev
        : [
            ...prev,
            {
              ...res.data[0],
              isSelected: prev.filter((v) => v.isSelected).length < countCuisineTypeSelected,
            },
          ];
    });

    setInputCuisineType({ id: 0, label: '' });
    setIsLoadingCreateCuisineType(false);
    setIsShowCuisineType(true);

    unfocus();
  };

  useEffect(() => {
    const cuisineTypes = initcuisineTypes.filter((ct) => ct.isActive);

    setCuisineTypes(cuisineTypes);
    setTempCuisineTypes(cuisineTypes);
    setDropdownCuisineTypes(cuisineTypes);
    setTimeout(() => setIsLoadingCuisineType(false), 500);
  }, [initcuisineTypes]);

  useEffect(() => {
    setDisabledCuisineType(
      cuisineTypes.filter((v) => v.isSelected).length >= countCuisineTypeSelected
    );
    setValue(
      'typeOfCuisineIds',
      cuisineTypes.filter((v) => v.isSelected).map((i) => i.id)
    );
  }, [cuisineTypes, setValue]);

  useEffect(() => {
    setTempDisabledCuisineType(
      tempCuisineTypes.filter((v) => v.isSelected).length >= countCuisineTypeSelected
    );
  }, [tempCuisineTypes]);

  const updateCuisineType = useCallback(() => {
    const data = formValue.typeOfCuisineIds;

    const prevTypeOfCuisine = (prev: CourseTypeOfCuisines[], start: number, end: number) => {
      return (
        prev
          ?.filter((ct) => ct.isActive)
          ?.map((v) => {
            const index = data.findIndex((f) => f === v.id);
            return index !== -1 ? { ...v, isSelected: true } : { ...v, isSelected: false };
          })
          ?.sort((x, y) => (x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1))
          ?.slice(start, end) || []
      );
    };

    setCuisineTypes((prev) => prevTypeOfCuisine(prev, 0, 9));
    setTempCuisineTypes((prev) => prevTypeOfCuisine(prev, 0, 9));
    setDropdownCuisineTypes((prev) => prevTypeOfCuisine(prev, 9, prev.length));
  }, [formValue]);

  useEffect(() => {
    if (!isLoadingCuisineType) {
      updateCuisineType();
    }
  }, [isLoadingCuisineType, updateCuisineType]);

  const onCloseDialog = () => setIsOpenDialog(false);

  const onSaveDialog = () => {
    setIsOpenDialog(false);
    setCuisineTypes(tempCuisineTypes);
  };

  const onSuggestCuisine = (id: number): void => {
    setCuisineTypes((prev) =>
      prev.map((v) => {
        if (v.id === id) v.isSelected = !v.isSelected;
        return v;
      })
    );
  };

  const onTempSuggestCuisine = (id: number): void => {
    setTempCuisineTypes((prev) =>
      prev.map((v) => {
        if (v.id === id) v.isSelected = !v.isSelected;
        return v;
      })
    );
  };

  const onDropdonwSuggestCuisine = (id: number): void => {
    setInputCuisineType({ id: 0, label: '' });
    setDropdownCuisineTypes((prev) =>
      prev.map((v) => {
        if (v.id === id) {
          v.isChoosed = !v.isChoosed;
        }
        return v;
      })
    );

    const typeOfCuisine = dropdownCuisineTypes?.find((f) => f.id === id);
    setTempCuisineTypes((prev) => {
      if (typeOfCuisine) {
        return [
          ...prev,
          ...[
            {
              ...typeOfCuisine,
              ...(prev.filter((v) => v.isSelected).length < countCuisineTypeSelected && {
                isSelected: true,
              }),
            },
          ],
        ];
      }
      return prev;
    });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        component="form"
        height={isMobile ? height : '750px'}
        paddingTop={6}
        paddingBottom={{ xs: 2, md: 0 }}
        flexWrap="nowrap"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item>
          <Typography variant="h4">What are your expertise?</Typography>
        </Grid>
        <Grid item flex="1 1 auto" overflow="auto">
          {cuisineTypes?.length > 0 && (
            <Box
              sx={{
                '> div': {
                  mr: 0.8,
                  mb: 0.8,
                },
                pt: 1.5,
                pb: 1.5,
              }}
            >
              {isLoadingCuisineType ? (
                <>
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} width="60%" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation="wave" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} width="80%" />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} />
                  <Skeleton sx={{ bgcolor: '#f3f3f3' }} animation={false} width="50%" />
                </>
              ) : (
                cuisineTypes.map((v) => (
                  <ChipWrap
                    disabled={disabledCuisineType && !v.isSelected}
                    isSelected={v.isSelected}
                    key={`cuisine_type_` + v.id}
                    label={v.name}
                    onDelete={() => onSuggestCuisine(v.id)}
                    onClick={() => onSuggestCuisine(v.id)}
                  />
                ))
              )}
              <div {...register('typeOfCuisineIds')} />
            </Box>
          )}

          {!isLoadingCuisineType && (
            <Box
              sx={{
                height: 23,
                '> a': {
                  userSelect: 'none',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  fontSize: 14,
                  color: '#212B36',
                  fontWeight: 'bold',
                  display: 'block',
                  '> span:first-of-type': {
                    position: 'relative',
                    top: 3,
                    marginRight: 1,
                  },
                },
              }}
            >
              {disabledCuisineType ? (
                <Typography
                  variant="body2"
                  color="#637381"
                  sx={{
                    fontSize: 12,
                    svg: {
                      position: 'relative',
                      top: 3,
                      fontSize: 16,
                    },
                  }}
                >
                  <ErrorIcon /> You have reach the maximum number of cuisine types
                </Typography>
              ) : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    onSearchCuisineType();
                  }}
                >
                  <span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.2583 9.66667H10.9167L15.075 13.8333L13.8333 15.075L9.66667 10.9167V10.2583L9.44167 10.025C8.49167 10.8417 7.25833 11.3333 5.91667 11.3333C2.925 11.3333 0.5 8.90833 0.5 5.91667C0.5 2.925 2.925 0.5 5.91667 0.5C8.90833 0.5 11.3333 2.925 11.3333 5.91667C11.3333 7.25833 10.8417 8.49167 10.025 9.44167L10.2583 9.66667ZM2.16667 5.91667C2.16667 7.99167 3.84167 9.66667 5.91667 9.66667C7.99167 9.66667 9.66667 7.99167 9.66667 5.91667C9.66667 3.84167 7.99167 2.16667 5.91667 2.16667C3.84167 2.16667 2.16667 3.84167 2.16667 5.91667Z"
                        fill="#576B75"
                      />
                    </svg>
                  </span>
                  <span>Search cuisine type</span>
                </a>
              )}
            </Box>
          )}
        </Grid>
        <Grid item pt={2} pb={3}>
          {errors.typeOfCuisineIds && (
            <Box pb={3}>
              <Alert severity="error">Please select at least one type</Alert>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                size="large"
                onClick={() => {
                  onFormSubmit(getValues().typeOfCuisineIds);
                  onBack();
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" fullWidth variant="contained" size="large">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal isOpen={isOpenDialog} onClose={onCloseDialog}>
        <Container>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            width={isMobile ? '100%' : '550px'}
            height={isMobile ? height : '820px'}
            paddingTop={9}
            paddingBottom={{ xs: 5, md: 3 }}
            px={{ md: 9, sx: 0 }}
          >
            <Grid item>
              <Box sx={{ mb: 3 }}>
                <Autocomplete
                  value={inputCuisineType}
                  disableClearable
                  noOptionsText={
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8}>
                        <Box sx={{ color: '#212B36', fontSize: '14px' }}>No matching item.</Box>
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        {inputCuisineType?.label?.replace(/^\s+|\s+$/gm, '')?.length ? (
                          <Box
                            sx={{
                              color: '#212B36',
                              fontWeight: 'bold',
                              fontSize: '14px',
                              textAlign: 'right',
                              '> a': {
                                userSelect: 'none',
                                textDecoration: 'none',
                                color: '#212B36',
                                display: 'block',
                                cursor: 'pointer',
                                '> svg': {
                                  position: 'relative',
                                  top: 2,
                                  marginRight: 1.3,
                                },
                              },
                            }}
                          >
                            {!isLoadingCreateCuisineType ? (
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  onCreateTypeOfCuisine();
                                }}
                              >
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.834 6.16683H7.83398V1.16683C7.83398 0.706592 7.46089 0.333496 7.00065 0.333496C6.54041 0.333496 6.16732 0.706592 6.16732 1.16683V6.16683H1.16732C0.70708 6.16683 0.333984 6.53992 0.333984 7.00016C0.333984 7.4604 0.70708 7.8335 1.16732 7.8335H6.16732V12.8335C6.16732 13.2937 6.54041 13.6668 7.00065 13.6668C7.46089 13.6668 7.83398 13.2937 7.83398 12.8335V7.8335H12.834C13.2942 7.8335 13.6673 7.4604 13.6673 7.00016C13.6673 6.53992 13.2942 6.16683 12.834 6.16683Z"
                                    fill="#212B36"
                                  />
                                </svg>
                                Add new
                              </a>
                            ) : (
                              <>Loading...</>
                            )}
                          </Box>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  }
                  onClose={() => setIsShowCuisineType(true)}
                  onOpen={() => setIsShowCuisineType(false)}
                  onChange={(_, v) => onDropdonwSuggestCuisine(v.id)}
                  onInputChange={(_, value) => setInputCuisineType({ id: 0, label: value })}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  options={
                    dropdownCuisineTypes
                      ?.filter((v) => !v.isChoosed)
                      ?.map((v) => {
                        return {
                          id: v.id,
                          label: v.name,
                        };
                      }) || []
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cuisine type"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        startAdornment: (
                          <InputAdornment position="start">
                            <svg
                              width="19"
                              height="19"
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M14.31 12.9L17.71 16.29C17.8993 16.4778 18.0058 16.7334 18.0058 17C18.0058 17.2666 17.8993 17.5222 17.71 17.71C17.5222 17.8993 17.2666 18.0058 17 18.0058C16.7334 18.0058 16.4778 17.8993 16.29 17.71L12.9 14.31C11.5025 15.407 9.77666 16.0022 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16.0022 9.77666 15.407 11.5025 14.31 12.9ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
                                fill="#919EAB"
                              />
                            </svg>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <Box sx={{ opacity: isShowCuisineType ? 1 : 0, ...(!isDesktop && { pb: 9 }) }}>
                <Typography variant="body2">Suggest cuisine type</Typography>
                <Box
                  sx={{
                    '> div': {
                      mr: 0.8,
                      mb: 0.8,
                    },
                    pt: 1.5,
                    pb: 1.5,
                    ...(isDesktop && { maxHeight: 250, overflow: 'auto' }),
                  }}
                >
                  {tempCuisineTypes?.length > 0 &&
                    tempCuisineTypes?.map((v) => (
                      <ChipWrap
                        disabled={(tempDisabledCuisineType && !v.isSelected) || !isShowCuisineType}
                        isSelected={v.isSelected}
                        key={`cuisine_model_type_` + v.id}
                        label={v.name}
                        onDelete={() => onTempSuggestCuisine(v.id)}
                        onClick={() => onTempSuggestCuisine(v.id)}
                      />
                    ))}
                </Box>
                {tempDisabledCuisineType && (
                  <Box>
                    <Typography
                      variant="body2"
                      color="#637381"
                      sx={{
                        fontSize: 12,
                        svg: {
                          position: 'relative',
                          top: 3,
                          fontSize: 16,
                        },
                      }}
                    >
                      <ErrorIcon /> You have reach the maximum number of cuisine types
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="outlined" onClick={onCloseDialog} fullWidth size="large">
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" onClick={onSaveDialog} fullWidth size="large">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Modal>
    </>
  );
};

export default CuisineTypes;
