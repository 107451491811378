import Image from '@components/Image';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';
import { resize_image } from '@utils/image';

interface Props {
  onAccept: () => void;
}

const TermsAndConditionsAcceptModal = forwardRef<unknown, Props>(({ onAccept }, ref) => {
  return (
    <Box ref={ref}>
      <Paper sx={{ p: 3, width: '90vw', maxWidth: '800px' }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm="auto">
            <Box display="flex">
              <Box display="flex" justifyContent="center" alignItems="center" mr={1}>
                <Image
                  sx={{ width: 40, height: 40 }}
                  src={resize_image({
                    url: 'https://images.potioneer.com/images-landing/cookies_lE_UR0Sxz.webp',
                    width: 40,
                    height: 40,
                  })}
                  alt="cookies icon"
                />
              </Box>
              <Box maxWidth="522px">
                <Typography variant="body2">
                  We use cookies to ensure that we give you the best experience on our website.{' '}
                  <Typography
                    component="a"
                    variant="subtitle2"
                    href="/terms-and-conditions?section=cookies"
                    target="_blank"
                  >
                    Read cookies policies.
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md="auto">
            <Button variant="contained" sx={{ px: 7 }} onClick={onAccept} fullWidth>
              Accept
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
});

export default TermsAndConditionsAcceptModal;
