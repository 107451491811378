export interface Response<T = any> {
  code: string;
  message: string;
  data: T;
}

export async function post<T = any>(
  url: string,
  body: any,
  headers?: HeadersInit
): Promise<Response<T>> {
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify(body),
  });

  return await res.json();
}

export async function put<T = any>(
  url: string,
  body: any,
  headers?: HeadersInit
): Promise<Response<T>> {
  const res = await fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    body: JSON.stringify(body),
  });

  return await res.json();
}

export async function post_form<T = any>(url: string, data: FormData): Promise<Response<T>> {
  const res = await fetch(url, {
    method: 'POST',
    body: data,
  });

  return await res.json();
}

export async function get<T = any>(
  url: string,
  headers?: HeadersInit,
  options?: Pick<RequestInit, 'signal'> | undefined
): Promise<Response<T>> {
  const res = await fetch(url, {
    method: 'GET',
    headers,
    ...(options ?? {}),
  });

  return await res.json();
}

export async function deletes<T = any>(url: string, headers?: HeadersInit): Promise<Response<T>> {
  const res = await fetch(url, {
    method: 'DELETE',
    headers,
  });

  return await res.json();
}

export async function get2(url: string, headers?: HeadersInit): Promise<any> {
  const res = await fetch(url, {
    method: 'GET',
    headers,
  });

  return await res.json();
}
