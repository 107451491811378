import { post } from '@utils/fetch';

export async function validateName(text: string, type: string, id: string, chefName?: string) {
  return await post(`${process.env.NEXT_PUBLIC_API_URL}/path-name/validate`, {
    id: Number(id),
    name: text,
    type: type,
    chefName: chefName,
  });
}
