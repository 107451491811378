import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { forwardRef, ReactElement, Ref } from 'react';
import { FunctionComponent } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { SxProps } from '@mui/material/styles';

interface ModalProp {
  isOpen: boolean;
  onClose: () => void;
  maxWidth?: Breakpoint;
  fullScreen?: boolean;
  fullWidth?: boolean;
  PaperProps?: any;
  isBackdropClick?: boolean;
  sx?: SxProps;
  idButtonClose?: string | undefined;
  closeColor?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal: FunctionComponent<ModalProp> = ({
  children,
  isOpen,
  onClose,
  fullScreen,
  fullWidth,
  maxWidth = 'sm',
  PaperProps,
  isBackdropClick,
  sx,
  idButtonClose,
  closeColor,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      sx={sx}
      fullScreen={fullScreen ?? matches}
      fullWidth={fullWidth}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={maxWidth}
      open={isOpen}
      onClose={isBackdropClick ? onClose : undefined}
      scroll="body"
    >
      {children}
      <IconButton
        id={idButtonClose ?? undefined}
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => (closeColor ? closeColor : theme.palette.grey[500]),
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default Modal;
