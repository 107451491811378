import { post, get, get2 } from '@utils/fetch';
import { sha256 } from 'js-sha256';
import jwt from 'jwt-decode';

interface JWT {
  userId: number;
  user: boolean;
  refresh: boolean;
  verify: boolean;
  reset: boolean;
  exp: number;
  iat: number;
  iss: string;
}

export interface Login {
  jwt: string;
  refresh: string;
}

export interface ResetPasswordToken {
  resetToken: string;
}

export async function get_access_token(): Promise<string> {
  const token = localStorage.getItem('act');
  if (token) {
    const decoded = jwt<JWT>(token);

    if (decoded.exp * 1000 - Date.now() > 5000) {
      return token;
    }
  }

  const refresh_token = localStorage.getItem('rft');
  if (!refresh_token) {
    return '';
  }

  const { code, data } = await refresh(refresh_token);

  if (code !== '200') {
    logout();
  } else {
    const result = data[0];

    save_token(result.jwt, result.refresh);

    return result.jwt;
  }

  return '';
}

function save_token(access: string, refresh: string) {
  localStorage.setItem('act', access);
  localStorage.setItem('rft', refresh);
}

export interface DinnerRegisterRequest {
  email: string;
  firstname: string;
  isAllowDisclaimer: boolean;
  lastname: string;
  password: string;
  invitationRefId?: string;
}

// export async function register(data: DinnerRegisterRequest) {
//   return await post(`${process.env.NEXT_PUBLIC_API_URL}/register/diner`, {
//     ...data,
//     password: sha256(data.password),
//   });
// }

export async function register(data: any, redirectUrl: string) {
  return await post(`${process.env.NEXT_PUBLIC_API_URL}/register/diner`, {
    ...data,
    password: sha256(data.password),
    redirectUrl: redirectUrl,
  });
}

export async function logout() {
  localStorage.removeItem('act');
  localStorage.removeItem('rft');
}

export async function validateTokenLogin(token: string) {
  const response = await get2(`${process.env.NEXT_PUBLIC_API_URL}/user/validate/open-web`, {
    Authorization: `Bearer ${token}`,
  });
  if (response.data) {
    if (response.data.length > 0) {
      const result = response.data[0];

      save_token(result.jwt, result.refresh);
    }
  }

  return response;
}

export async function login(email: string, password: string, onSuccess?: VoidFunction) {
  const response = await post<Login[]>(`${process.env.NEXT_PUBLIC_API_URL}/login`, {
    email,
    password: sha256(password),
  });

  if (response.code !== '200') {
    return response;
  } else {
    onSuccess && onSuccess();
  }

  if (response.data.length > 0) {
    const result = response.data[0];

    save_token(result.jwt, result.refresh);
  }

  return response;
}

export async function login_with_google(
  tokenType: string,
  accessToken: string,
  redirectUrl: string
) {
  const response = await post<Login[]>(`${process.env.NEXT_PUBLIC_API_URL}/login-with-google`, {
    tokenType,
    accessToken,
    redirectUrl: redirectUrl,
  });

  if (response.data.length > 0) {
    const result = response.data[0];

    save_token(result.jwt, result.refresh);
  }

  return response;
}

export async function login_with_facebook(accessToken: string, redirectUrl: string) {
  const response = await post<Login[]>(`${process.env.NEXT_PUBLIC_API_URL}/login-with-facebook`, {
    accessToken,
    redirectUrl: redirectUrl,
  });

  return response;
}

export async function refresh(token: string) {
  return await post<Login[]>(`${process.env.NEXT_PUBLIC_API_URL}/refresh`, {
    token,
  });
}

export async function verify_email(VerifyToken: string) {
  const response = await post<Login[]>(`${process.env.NEXT_PUBLIC_API_URL}/verify/email`, {
    VerifyToken,
  });

  if (response.data?.length > 0) {
    const result = response.data[0];

    save_token(result.jwt, result.refresh);
  }

  return response;
}

export async function reset_password_with_email(email: string) {
  return await post(`${process.env.NEXT_PUBLIC_API_URL}/reset/password-with-email`, {
    email,
  });
}

export async function verify_reset_password_token(token: string) {
  return await post<ResetPasswordToken[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/verify/reset-password-token`,
    {
      verifyToken: token,
    }
  );
}

export async function reset_password(token: string, password: string) {
  return await post(`${process.env.NEXT_PUBLIC_API_URL}/reset-password`, {
    newPassword: sha256(password),
    resetToken: token,
  });
}

export async function delete_account(token: string, reason: string) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/delete-account`,
    {
      reason,
    },
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export function get_ig_auth(token: string, code: string) {
  return get(`${process.env.NEXT_PUBLIC_API_URL}/ig/auth?code=${code}`, {
    Authorization: `Bearer ${token}`,
  });
}

export function resend_verify_email(email: string) {
  return post(`${process.env.NEXT_PUBLIC_API_URL}/verify/resend-email`, {
    email,
  });
}
