import { atom, RecoilState } from 'recoil';

interface SearchBarState {
  expanded: boolean;
}

interface SearchBarOnKeyboardState {
  expanded: boolean;
}

export const searchbarAtomDefaultState: SearchBarState = {
  expanded: false,
};

export const searchbarAtom: RecoilState<SearchBarState> = atom({
  key: 'SEARCHBAR_STATE',
  default: searchbarAtomDefaultState,
});

export const searchbarOnKeyboardAtomDefaultState: SearchBarOnKeyboardState = {
  expanded: false,
};

export const searchbarOnKeyboardAtom: RecoilState<SearchBarOnKeyboardState> = atom({
  key: 'SEARCHBAR_ON_BOARD_STATE',
  default: searchbarOnKeyboardAtomDefaultState,
});
