import { authState } from '@atoms/auth';
import Modal from '@components/Modal';
import useResponsive from '@hooks/useResponsive';
import { FunctionComponent, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import CheckYourEmail from './CheckYourEmail';
import ResetPassword from './ResetPassword';
import SignInSignUp from './SignInSignUp';
import VerifyYourEmail from './VerifyYourEmail';

interface SignInSignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSignInSuccessCallback?: () => void;
  onSignUpSuccessCallback?: () => void;
  auth: boolean;
}

const SignInSignUpModal: FunctionComponent<SignInSignUpModalProps> = ({
  isOpen,
  onClose,
  onSignInSuccessCallback,
  onSignUpSuccessCallback,
  auth,
}) => {
  const isMoblie = useResponsive('down', 'sm');

  const [state, setStatus] = useState<string>('sign-in-sign-up');

  const [email, setEmail] = useState<string>('');

  const setAuth = useSetRecoilState(authState);

  const onForgotPassword = () => {
    setStatus('reset-password');
  };

  const onSignInSuccess = () => {
    setAuth({ auth: true, loading: false });
    onModalClose();
    if (onSignInSuccessCallback) onSignInSuccessCallback();
  };

  const onSignUpSuccess = (email: string) => {
    setEmail(email);
    setStatus('verify-your-email');
    if (onSignUpSuccessCallback) onSignUpSuccessCallback();
  };

  const onResetPasswordSuccess = (email: string) => {
    setEmail(email);
    setStatus('check-reset-password-email');
  };

  const onCheckYourEmailTryAgain = () => {
    setStatus('reset-password');
  };

  const onBackToLogin = () => {
    setStatus('sign-in-sign-up');
  };

  const onModalClose = () => {
    onClose();

    setTimeout(() => {
      setStatus('sign-in-sign-up');
      setEmail('');
    }, 500);
  };

  const content = () => {
    switch (state) {
      case 'verify-your-email':
        return <VerifyYourEmail email={email} isMoblie={isMoblie} />;
      case 'reset-password':
        return <ResetPassword onSuccess={onResetPasswordSuccess} />;
      case 'check-reset-password-email':
        return (
          <CheckYourEmail
            email={email}
            onTryAgain={onCheckYourEmailTryAgain}
            onBackToLogin={onBackToLogin}
          />
        );
      default:
        return (
          <SignInSignUp
            onSignUpSuccess={onSignUpSuccess}
            onSignInSuccess={onSignInSuccess}
            onForgotPassword={onForgotPassword}
            isOpen={isOpen}
            auth={auth}
          />
        );
    }
  };

  return (
    <Modal
      idButtonClose={
        state === 'verify-your-email'
          ? undefined
          : state === 'reset-password'
          ? undefined
          : state === 'check-reset-password-email'
          ? undefined
          : 'Register_close'
      }
      isOpen={isOpen}
      onClose={onModalClose}
      isBackdropClick={state === 'verify-your-email' ? false : true}
      maxWidth="xs"
      fullWidth
    >
      {content()}
    </Modal>
  );
};

export default SignInSignUpModal;
