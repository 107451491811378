import { authState } from '@atoms/auth';
import { searchbarAtom } from '@atoms/searchbar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get_recent_keyword, get_search_suggestions } from '@services/search';
import { AnimatePresence, m } from 'framer-motion';
import { useRouter } from 'next/router';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import SearchBarV3DesktopView, { FieldOption, Suggestion } from './SearchBarV3DesktopView';
import SearchBarV3MobileView from './SearchBarV3MobileView';
import { get_access_token } from '@services/auth';
import { venueOptions, timeOptions, priceOptions } from './fieldOptions';
import _ from 'lodash';

const SearchBarV3: FunctionComponent = () => {
  const constroller = useRef<AbortController>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const router = useRouter();
  const [isWhatToEatPopoverOpen, setIswhatToEatPopoverOpen] = useState(false);
  const [isRecentKeywordPopoverOpen, setIsRecentKeywordPopoverOpen] = useState(false);
  const [whatToEatVal, setWhatToEatVal] = useState<string>('');
  const [venueVal, setVenueVal] = useState<FieldOption>();
  const [timeVal, setTimeVal] = useState<FieldOption>();
  const [dateVal, setDateVal] = useState<Date>();
  const [guestVal, setGuestVal] = useState<number>(0);
  const [priceVal, setPriceVal] = useState<FieldOption>();
  const [suggestionsVal, setSuggestionsVal] = useState<Suggestion[]>([]);
  const [recentKeywordVal, setRecentKeywordVal] = useState<string[]>([]);
  const [shouldShowSuggestion, setShouldShowSuggestion] = useState(true);
  const searchbarState = useRecoilValue(searchbarAtom);
  const auth = useRecoilValue(authState);

  useEffect(() => {
    const { keyword, venue, time, date, minGuests, maxGuests, minPrice, maxPrice } = router.query;
    if (keyword) setWhatToEatVal(keyword as string);
    if (venue) {
      const venueOption = venueOptions.find(({ value }) => value === venue);
      venueOption && setVenueVal(venueOption);
    }
    if (time) {
      const timeOption = timeOptions.find(({ value }) => value === time);
      timeOption && setTimeVal(timeOption);
    }
    if (date) setDateVal(new Date(date as string));
    if (maxGuests && minGuests) setGuestVal(Number(maxGuests));
    if (minPrice && maxPrice) {
      const priceOption = priceOptions.find(({ value }) => value === `${minPrice}-${maxPrice}`);
      priceOption && setPriceVal(priceOption);
    } else if (minPrice) {
      const priceOption = priceOptions.find(({ value }) => value === minPrice);
      priceOption && setPriceVal(priceOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelAndCreateNewInstanceSearchSuggestion = (): AbortController => {
    if (constroller.current) {
      constroller.current.abort();
    }
    constroller.current = new AbortController();
    return constroller.current;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    _.throttle((value: string) => {
      const control = cancelAndCreateNewInstanceSearchSuggestion();
      const { signal } = control;
      get_search_suggestions(value, { signal })
        .then((res) => {
          if (res?.code !== '200') {
            return;
          }
          if (res?.data?.length == 0) {
            return;
          }
          const suggestions: Suggestion[] = [];
          const data = res?.data[0];
          if (data != undefined && data.chefs.length > 0) {
            const chefs = data.chefs.map((item) => {
              return {
                id: item.id,
                title: item.title,
                type: 'CHEF',
                label: 'Chef',
              };
            });
            suggestions.push(...chefs);
          }
          if (data != undefined && data.courses.length > 0) {
            const courses = data.courses.map((item) => {
              return {
                id: item.id,
                title: item.title,
                type: 'COURSE',
                label: 'Course Menu',
              };
            });
            suggestions.push(...courses);
          }
          setSuggestionsVal(suggestions);
          if (suggestions.length > 0) {
            shouldShowSuggestion && setIswhatToEatPopoverOpen(true);
          } else {
            setIswhatToEatPopoverOpen(false);
          }
        })
        .catch((error) => {
          if (error.name === 'AbortError') return;
          console.error('Error ', error);
        });
    }, 500),
    [shouldShowSuggestion]
  );

  const fetchRecentKeyword = useCallback(() => {
    if (auth.auth) {
      get_access_token().then((token) => {
        get_recent_keyword(token).then((res) => {
          if (res?.code !== '200') {
            return;
          }
          if (res?.data?.length == 0) {
            return;
          }
          setRecentKeywordVal(res.data);
        });
      });
    } else {
      const recentKeyword = localStorage.getItem('recentKeyword');
      if (recentKeyword) {
        setRecentKeywordVal(JSON.parse(recentKeyword));
      }
    }
  }, [auth.auth]);

  useEffect(() => {
    if (whatToEatVal.length == 0) {
      fetchRecentKeyword();
      setIswhatToEatPopoverOpen(false);
      setSuggestionsVal([]);
    }
  }, [fetchSuggestions, fetchRecentKeyword, whatToEatVal]);

  const onSearch = useCallback(() => {
    const priceRange = priceVal
      ? {
          minPrice: priceVal.value.split('-')[0],
          maxPrice: priceVal.value.split('-').length > 1 ? priceVal.value.split('-')[1] : undefined,
        }
      : undefined;
    console.log('priceRange: ', priceRange);
    cancelAndCreateNewInstanceSearchSuggestion();
    router.push(
      {
        pathname: '/search',
        query: {
          keyword: whatToEatVal && whatToEatVal.trim(),
          venue: venueVal && venueVal.value,
          time: timeVal && timeVal.value,
          date: dateVal && dateVal.toString(),
          minGuests: guestVal && guestVal > 0 ? guestVal : '',
          maxGuests: guestVal && guestVal > 0 ? guestVal : '',
          minPrice: priceRange && priceRange.minPrice,
          maxPrice: priceRange && priceRange.maxPrice,
        },
      },
      '/search'
    );
  }, [priceVal, router, timeVal, dateVal, guestVal, venueVal, whatToEatVal]);

  const handleWhatToEat = useCallback(
    ({ value, isPopup }: { value: string | undefined; isPopup?: boolean }) => {
      if (!isPopup && value && value.length >= 2) {
        fetchSuggestions(value);
      }
      setWhatToEatVal(value ?? '');
    },
    [fetchSuggestions]
  );

  return isDesktop ? (
    <AnimatePresence initial={false}>
      {searchbarState.expanded && (
        <m.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            stiffness: 100,
            duration: 0.2,
          }}
        >
          <SearchBarV3DesktopView
            suggestionsVal={suggestionsVal}
            recentKeywordVal={recentKeywordVal}
            setShouldShowSuggestion={setShouldShowSuggestion}
            isWhatToEatPopoverOpen={isWhatToEatPopoverOpen}
            setIswhatToEatPopoverOpen={setIswhatToEatPopoverOpen}
            setIsRecentKeywordPopoverOpen={setIsRecentKeywordPopoverOpen}
            isRecentKeywordPopoverOpen={isRecentKeywordPopoverOpen}
            setWhatToEatVal={handleWhatToEat}
            setVenueVal={setVenueVal}
            setTimeVal={setTimeVal}
            setDateVal={setDateVal}
            setGuestVal={setGuestVal}
            setPriceVal={setPriceVal}
            whatToEatVal={whatToEatVal}
            timeVal={timeVal}
            dateVal={dateVal}
            guestVal={guestVal}
            priceVal={priceVal}
            venueVal={venueVal}
            onSearch={onSearch}
          />
        </m.div>
      )}
    </AnimatePresence>
  ) : (
    <AnimatePresence initial={false}>
      {searchbarState.expanded && (
        <m.div
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{
            stiffness: 500,
            duration: 0.5,
          }}
        >
          <SearchBarV3MobileView
            suggestionsVal={suggestionsVal}
            recentKeywordVal={recentKeywordVal}
            setShouldShowSuggestion={setShouldShowSuggestion}
            isWhatToEatPopoverOpen={isWhatToEatPopoverOpen}
            setIswhatToEatPopoverOpen={setIswhatToEatPopoverOpen}
            setIsRecentKeywordPopoverOpen={setIsRecentKeywordPopoverOpen}
            isRecentKeywordPopoverOpen={isRecentKeywordPopoverOpen}
            setWhatToEatVal={handleWhatToEat}
            setVenueVal={setVenueVal}
            setTimeVal={setTimeVal}
            setDateVal={setDateVal}
            setGuestVal={setGuestVal}
            setPriceVal={setPriceVal}
            whatToEatVal={whatToEatVal}
            timeVal={timeVal}
            dateVal={dateVal}
            guestVal={guestVal}
            priceVal={priceVal}
            venueVal={venueVal}
            onSearch={onSearch}
          />
        </m.div>
      )}
    </AnimatePresence>
  );
};
export default SearchBarV3;
