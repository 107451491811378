import { useRef, useState, forwardRef, ReactElement, Ref } from 'react';
// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { useEffect } from 'react';
import {
  get_unread_notification,
  NotificationItem as NotiItem,
  post_notification_read,
  post_notification_read_all,
} from '@services/notification';
import { get_access_token } from '@services/auth';
import { get_notifications } from '@services/notification';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { profileState } from '@atoms/profile';
import { useRecoilValue } from 'recoil';
import { DatabaseReference, equalTo, orderByChild, query, ref } from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { Contact, Conversation } from 'src/types/chat';
import { get_short_profile, ShortProfile } from '@services/profile';
import moment from 'moment';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { database } from '@utils/firebase';
import { TransitionProps } from '@mui/material/transitions';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { resize_image } from '@utils/image';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const profilemap: Record<number, ShortProfile> = {};

const NOTIFICATION_LIMIT = 20;

const notificationIconMap: { [key: string]: boolean } = {
  PENDING_BOOKING: true, // chef has received ur booking

  // New
  // Diner
  DINER_PENDING_BOOKING: true, // Chef has received your dinner request.
  PENDING_PAYMENT: true, // Chef has accepted your dinner request.
  DECLINED: true, // Your dinner request has been declined.
  CHEF_EDITED_BOOKING: true, // Chef has edited your dinner booking.
  CHEF_PIN_ON_YOUR_REVIEW: true, // Chef has pinned your review.

  // Chef
  CHEF_PENDING_BOOKING: true, // {Diner name} has sent you a dinner request.
  CHEF_PAYMENT_MADE: true, // {Diner name} has made a payment.
  VENUE_REQUEST_ACCEPTED_PLEASE_PUBLISH_COURSE: true, // {venue owner name} has accepted your venue affiliation request.
  VENUE_REQUEST_ACCEPTED: true, // {venue owner name} has accepted your venue affiliation request.
  VENUE_REQUEST_DECLINED: true, // {venue owner name} has declined your venue affiliation request.

  // Venue
  VENUE_OWNER_PAYMENT_MADE: true, // {Diner name} has made a payment.
  CHEF_REQUEST_TO_USE_VENUE: true, // {Chef name} would like to host private dinners at your venue, {venue name}
  VENUE_BOOKING: true, // {Chef name} has confirmed a dinner event at your venue, {venue name}
};

// check type of notification is component <p> or <span>
const notificationDescriptionMap: { [key: string]: boolean } = {
  // Diner
  REMAINING_PAID_SUCCESSFUL: true, // Remaining payment successful.

  // Chef
  CHEF_WITHDRAW_REQUEST: true, // Withdrawal requested.
  CHEF_WITHDRAW_COMPLETED: true, // Withdrawal completed.
  CHEF_PAYMENT_MADE: true, // {Diner name} has made a payment.
  CHEF_EDITED_BOOKING: true, // Chef has edited your dinner booking.
  CHEF_PIN_ON_YOUR_REVIEW: true, // Chef has pinned your review.
  VENUE_REQUEST_ACCEPTED_PLEASE_PUBLISH_COURSE: true, // {venue owner name} has accepted your venue affiliation request.
  VENUE_REQUEST_ACCEPTED: true, // {venue owner name} has accepted your venue affiliation request.

  // Venue
  VENUE_OWNER_WITHDRAW_REQUEST: true, // Withdrawal requested.
  VENUE_OWNER_WITHDRAW_COMPLETED: true, // Withdrawal completed.
  VENUE_OWNER_PAYMENT_MADE: true, // {Diner name} has made a payment.
};

type NotificationsPopoverProps = {
  isCourseMenu?: boolean;
};

export default function NotificationsPopover({ isCourseMenu }: NotificationsPopoverProps) {
  const anchorRef = useRef(null);
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationItemProps[]>([]);
  const [numberOfUnread, setNumberOfUnread] = useState(0);
  const [totalNotis, setTotalNotis] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [selectTab, setSelectTab] = useState(0);
  const profile = useRecoilValue(profileState);
  const inboxesref: DatabaseReference = ref(database, 'inboxes');
  const [inboxessnapshot] = useList(query(inboxesref, orderByChild('from'), equalTo(profile.id)));
  const [allChats, setAllChats] = useState<Conversation[]>([]);
  const [unreadCountChats, setUnreadCountChats] = useState<number>(0);
  const [conversationAllIds, setConversationAllIds] = useState<string[]>([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    get_access_token()
      .then((token) => {
        post_notification_read_all(token).then(({ code }) => {
          if (code !== '200') {
            return;
          }

          setNotifications(
            notifications.map((notification) => ({
              ...notification,
              isUnRead: false,
            }))
          );
        });
      })
      .finally(() => setNumberOfUnread(0));
  };
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectTab(newValue);
  };

  const handleSelectConversation = (conversationId: string) => {
    router.push(`/chat?conversationId=${conversationId}`);
  };

  useEffect(() => {
    const fetchNumberOfUnread = (token: string) => {
      get_unread_notification(token).then((res) => {
        if (res?.data?.length > 0) {
          setNumberOfUnread(res.data[0].unread);
        } else {
          setNumberOfUnread(0);
        }
      });
    };

    const fetchNotifications = (token: string) => {
      get_notifications(token, page, NOTIFICATION_LIMIT).then((res) => {
        const total = res?.data?.flatMap((r) => r.total).reduce((a, b) => a + b, 0);
        const unReadNotis: NotificationItemProps[] = res?.data
          .flatMap((r) => r.data)
          .map((r) => {
            const notiItem: NotificationItemProps = {
              id: r.id,
              title: r.title,
              description: r.description,
              isUnRead: !r.isRead,
              type: r.type,
              avatar: r.imageThumbnail?.imageUrl,
              createdAt: new Date(r.createdAt),
              refId: r.refId,
              receiverName: r.receiverName,
              remark: r.remark,
              redirectUrl: r.redirectUrl,
            };
            return notiItem;
          });

        setTotalNotis(total);
        setNotifications((prev) => [...prev, ...unReadNotis]);
        // setNotifications(mockNoti);
        setTotalPages(Math.ceil(total / NOTIFICATION_LIMIT));
      });
    };

    get_access_token().then((token) => {
      fetchNumberOfUnread(token);
      fetchNotifications(token);
    });
  }, [page]);

  useEffect(() => {
    if (!inboxessnapshot) {
      return;
    }
    const fetchInboxes = async () => {
      const currentConversations: Conversation[] = [];

      const inboxes: any[] = [];
      const profileids: number[] = [];

      for (const inboxe of inboxessnapshot) {
        const value = inboxe.val();
        if (!profilemap[value.to as number]) profileids.push(value.to as number);
        inboxes.push(value);
      }

      const conversationAllIds = inboxes
        .sort((a: any, b: any) => {
          return b.timestamp - a.timestamp;
        })
        .map((inbox) => inbox.coversationId as string);

      if (conversationAllIds.length === 0) {
        return;
      }
      setConversationAllIds(conversationAllIds);

      if (profileids.length !== 0) {
        const token = await get_access_token();

        const res = await get_short_profile(token, profileids);

        if (res?.code !== '200') {
          // alert(res?.message);
          return;
        }

        for (const shortprofile of res?.data) {
          profilemap[shortprofile.id] = shortprofile;
        }
      }

      setUnreadCountChats(0);
      for (const inbox of inboxes) {
        const userprofile = profilemap[inbox.to as number];

        if (!userprofile) continue;
        currentConversations.push({
          id: inbox.coversationId,
          senderId: profile.id,
          type: 'private',
          participants: {
            id: userprofile.id,
            name: userprofile.displayName,
            avatar: userprofile.imageProfileUrl,
          },
          unreadCount: inbox.unReadCount ?? 0,
          messages: [],
          lastMessage: inbox.lastMessage,
          timestamp: inbox.timestamp,
        });
        setUnreadCountChats((prev) => prev + (inbox.unReadCount ?? 0));
      }
      setAllChats(currentConversations);
    };
    fetchInboxes();
  }, [inboxessnapshot, profile.id]);

  const markAsRead = () => {
    setNumberOfUnread((prev) => {
      if (prev > 0) {
        return prev - 1;
      }
      return 0;
    });
  };

  return (
    <>
      <IconButtonAnimate
        id="Navbar_inbox"
        ref={anchorRef}
        size="large"
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
      >
        <Badge badgeContent={numberOfUnread + unreadCountChats} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          width: 360,
          height: 455,
          overflowY: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tabs value={selectTab} onChange={handleChangeTab}>
            <Tab
              id="Inbox_notifications"
              icon={
                <Badge
                  color="error"
                  badgeContent={numberOfUnread}
                  // variant={numberOfUnread > 0 ? 'dot' : undefined}
                >
                  <NotificationsRoundedIcon fontSize="small" />
                </Badge>
              }
              iconPosition="start"
              label="Notifications"
              sx={{ width: 140 }}
            />
            <Tab
              id="Inbox_chats"
              icon={
                <Badge
                  color="error"
                  badgeContent={unreadCountChats}
                  // variant={unreadCountChats > 0 ? 'dot' : undefined}
                >
                  <TextsmsRoundedIcon fontSize="small" />
                </Badge>
              }
              iconPosition="start"
              label="Chats"
            />
          </Tabs>
        </Box>

        {/* <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {numberOfUnread} unread messages
            </Typography>
          </Box>

          {numberOfUnread > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box> */}

        <Divider sx={{ marginTop: '3%' }} />
        {selectTab === 0 && (
          <>
            {totalNotis === 0 ? (
              <Box sx={{ width: '100%', height: '100%' }}>
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  mt={10}
                >
                  <svg
                    width="131"
                    height="135"
                    viewBox="0 0 131 135"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M129.669 33.3151C121.612 29.6072 115.27 23.8257 109.116 17.5807C101.676 10.0306 94.8577 6.85935 84.3802 10.0916C77.4522 12.2261 65.8892 18.2638 69.0849 27.3507C72.9636 38.3953 78.3365 34.5105 78.3365 34.5105C78.3365 34.5105 83.1117 30.3329 78.9342 28.8388C72.3904 26.503 63.3705 36.5718 71.1767 41.2312C76.0556 44.1402 80.7089 46.0674 83.8802 51.0194C87.9296 57.3437 88.4785 65.4122 88.1858 72.7061C86.9905 102.553 88.4846 110.011 92.961 113.292C97.4374 116.573 95.6444 124.935 95.6444 124.935C95.6444 124.935 49.9841 101.955 24.0161 103.748C24.0161 103.748 99.5231 127.624 95.0467 131.204C93.144 132.723 89.0823 131.948 86.9112 131.753C82.0872 131.314 77.2998 130.436 72.5489 129.515C67.5908 128.551 62.4984 126.966 57.4488 125.849C54.369 125.166 51.405 127.124 50.5573 130.161C50.5573 130.18 50.5451 130.198 50.5451 130.216C48.6058 136.998 38.7321 132.631 37.4087 127.6C36.006 122.282 43.2634 113.762 48.6789 118.007C49.3193 118.507 49.7767 119.239 49.8011 120.05C49.856 122.001 47.6239 122.818 45.8065 122.867C36.2927 123.142 25.1871 117.793 16.8564 113.664C12.6117 111.56 0.243726 106.188 1.03655 99.8633C2.23188 90.3128 56.2473 98.9668 56.2473 98.9668L66.3954 101.949C66.3954 101.949 27.8949 88.5198 11.1846 88.221C11.1846 88.221 4.61643 87.3245 15.3622 83.147C26.108 78.9694 36.5488 58.3805 37.1465 55.0933C37.9088 50.8975 40.7202 46.9456 43.9464 44.2866C46.8738 41.8715 50.4232 40.3591 54.125 39.5967C57.1499 38.9747 61.0591 39.4991 63.4803 37.2122C66.4564 34.4007 67.2858 28.9729 66.0905 25.2467C63.3705 16.7879 50.3256 10.0062 43.0621 6.42025C37.0916 3.46851 30.1818 0.894899 23.4185 1.18153C18.0883 1.40718 10.776 5.77379 10.3918 11.6346C9.66609 22.795 28.0168 24.1672 35.5547 23.8928C46.5017 23.4964 57.3207 20.6483 68.2433 19.6603C77.0924 18.8614 87.643 18.1967 95.6322 22.8621C109.958 31.2172 127.863 50.3181 116.227 55.691C104.585 61.0639 89.9727 34.2055 89.9727 34.2055"
                      stroke="#212B36"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }} fontWeight={700}>
                    You have no notifications
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <>
                <Scrollbar sx={{ maxHeight: 390 }}>
                  <List
                    disablePadding
                    subheader={
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        pr={1}
                      >
                        <ListSubheader
                          disableSticky
                          sx={{ py: 1, px: 2.5, typography: 'overline' }}
                        >
                          New
                        </ListSubheader>
                        {numberOfUnread > 0 && (
                          <Tooltip title=" Mark all as read">
                            <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                              <Iconify icon="eva:done-all-fill" width={20} height={20} />
                            </IconButtonAnimate>
                          </Tooltip>
                        )}
                      </Stack>
                    }
                  >
                    {notifications.slice(0, 3).map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                        markAsRead={markAsRead}
                        isCourseMenu={isCourseMenu}
                      />
                    ))}
                  </List>

                  {totalNotis > 3 && (
                    <List
                      disablePadding
                      subheader={
                        <ListSubheader
                          disableSticky
                          sx={{ py: 1, px: 2.5, typography: 'overline' }}
                        >
                          Before that
                        </ListSubheader>
                      }
                    >
                      {notifications.slice(3).map((notification) => (
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                          markAsRead={markAsRead}
                          isCourseMenu={isCourseMenu}
                        />
                      ))}
                    </List>
                  )}
                  {totalPages != page && (
                    <Box sx={{ p: 1 }}>
                      <Button
                        id="Inbox_OlderNoti"
                        fullWidth
                        disableRipple
                        onClick={() => setPage((prev) => prev + 1)}
                      >
                        Older Notifications
                      </Button>
                    </Box>
                  )}
                </Scrollbar>
                <Divider />
              </>
            )}
          </>
        )}
        {selectTab === 1 && (
          <>
            {/* <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}> */}
            <Box sx={{ height: { xs: 325 } }}>
              {allChats.length === 0 ? (
                <Box sx={{ width: '100%', height: '100%' }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    mt={10}
                  >
                    <svg
                      width="210"
                      height="110"
                      viewBox="0 0 210 110"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 95.1157H63.8764C64.6477 95.1172 65.4055 95.3328 66.0767 95.7416C66.748 96.1504 67.3099 96.7386 67.7081 97.4493C68.1064 98.1601 68.3277 98.9694 68.3504 99.799C68.3732 100.629 68.1967 101.45 67.8381 102.185L64.3163 109.341L82.2912 97.3534C83.6312 96.4625 85.1485 95.9262 86.7185 95.7888C89.8376 95.5091 95.5131 93.8155 96.8984 85.3751C98.835 73.572 123.674 25.3177 88.1895 21.1344C52.7053 16.9511 19.152 14.5843 18.8292 30.1988C18.5064 45.8133 14.3134 89.9088 40.1232 88.8668C65.933 87.8248 93.9964 92.6844 103.042 83.3126C112.088 73.9409 98.2409 21.1651 90.2546 16.4807C84.0192 12.8199 27.8324 0.660362 15.9415 22.2133C14.2934 25.1979 13.0537 92.3371 49.8147 90.6035C86.5757 88.8699 95.6159 80.8813 96.8984 73.2462C98.1809 65.6111 97.5782 36.9763 96.2529 29.8514C94.9276 22.7265 92.7053 6.93994 102.06 4.50863C109.541 2.56604 158.064 -0.928743 184.468 1.47183C188.922 1.87634 193.17 3.66564 196.695 6.62188C200.221 9.57812 202.872 13.5737 204.325 18.1221C210.038 36.0511 215.319 65.4236 189.186 68.5526C169.958 70.8549 150.806 68.5311 131.557 68.1039C130.717 68.0854 129.769 68.0178 129.215 67.3355C128.661 66.6531 128.767 65.4082 129.372 64.692C132.034 61.5384 137.847 61.52 141.474 61.3109C155.185 60.481 168.815 60.7915 182.411 62.8478C227.416 69.61 183.137 73.7841 183.137 73.7841C160.623 74.07 138.021 74.9368 115.525 74.5464C114.039 74.5187 112.34 75.0197 111.714 76.4705C111.174 77.7277 111.68 79.2768 112.571 80.2635C115.896 83.8351 121.609 86.5093 125.585 89.2756L134.276 95.3616C133.134 92.7735 126.444 81.3454 130.278 80.4141C133.859 79.5411 139.209 80.5186 142.951 80.5186L194.87 80.2635"
                        stroke="#212B36"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }} fontWeight={700}>
                      No message yet
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} textAlign="center">
                      A chat box with Chef will appear here, once <br /> you’ve sent a dinner
                      request.
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                allChats.slice(0, 5).map((data, idx) => {
                  return (
                    <Box
                      key={idx}
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        py: 1.4,
                        px: 2.5,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleSelectConversation(conversationAllIds[idx]);
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        {data?.participants?.avatar ? (
                          <Avatar
                            alt={data?.participants?.name + '_img'}
                            src={resize_image({
                              url: data?.participants?.avatar,
                              width: 48,
                              height: 48,
                            })}
                            sx={{ width: 48, height: 48 }}
                          >
                            {data?.participants?.name
                              .split(' ')
                              .slice(0, 2)
                              .map((n) => n[0])
                              .join('')}
                          </Avatar>
                        ) : (
                          <Avatar alt="avatar" sx={{ width: 48, height: 48 }}>
                            {data?.participants?.name
                              .split(' ')
                              .slice(0, 2)
                              .map((n) => n[0])
                              .join('')}
                          </Avatar>
                        )}
                        {data?.lastMessage && (
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            spacing={0.2}
                            pl={1}
                          >
                            <Typography variant="subtitle2">
                              {data?.participants?.name && data?.participants?.name?.length > 19
                                ? data?.participants?.name.slice(0, 19) + '...'
                                : data?.participants?.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {data?.lastMessage.length > 20
                                ? data?.lastMessage.slice(0, 20) + '...'
                                : data?.lastMessage}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-end"
                        spacing={2.25}
                      >
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          {moment(data?.timestamp).fromNow().replace('ago', '')}
                        </Typography>

                        <Badge
                          color="error"
                          // badgeContent={data?.unreadCount}
                          variant={data?.unreadCount > 0 ? 'dot' : undefined}
                          sx={{
                            '& .MuiBadge-badge': { right: 5 },
                          }}
                        />
                      </Stack>
                    </Box>
                  );
                })
              )}
            </Box>
            <Divider style={{ marginTop: '6%' }} />
            <Box sx={{ background: 'white' }}>
              <Button
                id="Inbox_seeallchats"
                fullWidth
                // disableRipple
                sx={{
                  // background: 'white',
                  pt: 1,
                }}
                onClick={() =>
                  router.push(`chat`, undefined, {
                    shallow: true,
                  })
                }
              >
                See all chats
              </Button>
            </Box>
            {/* </Scrollbar> */}
          </>
        )}
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: number;
  title: string;
  description: string;
  avatar?: string;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
  refId?: number;
  receiverName?: string;
  remark?: string;
  redirectUrl?: string;
};

type NotificationItemAllProps = {
  notification: NotificationItemProps;
  markAsRead: VoidFunction;
  isCourseMenu?: boolean;
};

function NotificationItem({ notification, markAsRead, isCourseMenu }: NotificationItemAllProps) {
  const { title } = renderContent(notification);
  const router = useRouter();

  const { avatar, id, type } = notification;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [idNotification, setIdNotification] = useState<number>(0);
  const [typeNotification, setTypeNotification] = useState<string>('');
  const [refIdNotification, setRefIdNotification] = useState<number>();
  const [remarkNotification, setRemarkNotification] = useState<string>('');
  const [redirectUrlNotification, setRedirectUrlNotification] = useState<string>('');

  useEffect(() => {
    if (isSubmit)
      handleOnClickNotification(
        idNotification,
        typeNotification,
        remarkNotification,
        redirectUrlNotification,
        refIdNotification
      );
    // eslint-disable-next-line
  }, [
    isSubmit,
    idNotification,
    typeNotification,
    remarkNotification,
    redirectUrlNotification,
    refIdNotification,
  ]);

  const onReasonSubmit = () => {
    setIsSubmit(true);
    onClose();
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const handleMarkAsRead = (notificationId: number) => {
    get_access_token().then((token) => {
      post_notification_read(token, notificationId);
    });
  };

  const handleOnClickNotification = (
    id: number,
    type: string,
    remark: string,
    redirectUrl: string,
    refId?: number
  ) => {
    switch (type) {
      // type of notification old version
      case 'PENDING_BOOKING':
        router.push(`/booking?bid=${refId}`);
        break;

      // Diner
      case 'DINER_REGISTERED':
        redirectUrl !== '' ? router.push(redirectUrl) : router.push(`/account/profile`);
        break;
      case 'PAYMENT_SUCCESSFUL':
        redirectUrl !== ''
          ? router.push(redirectUrl)
          : router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'PAYMENT_REJECTED':
        router.push(`/booking?tab=decline&bid=${refId}`);
        break;
      case 'DINER_BOOKING_CANCELED':
        router.push(`/booking?tab=decline&bid=${refId}`);
        break;
      case 'REVIEW':
        router.push(`/booking?tab=history&bid=${refId}`);
        break;
      case 'DEPOSIT_PAID_SUCCESSFUL':
        router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'PAYMENT_OVERDUE':
        router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'REMAINING_PAID_SUCCESSFUL':
        router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'BEFORE_EATING':
        router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'DINER_PENDING_BOOKING':
        router.push(`/booking?tab=Pending&bid=${refId}`);
        break;
      case 'PENDING_PAYMENT':
        router.push(`/booking?tab=Pending&bid=${refId}`);
        break;
      case 'DECLINED':
        router.push(`/booking?tab=decline&bid=${refId}`);
        break;
      case 'CHEF_EDITED_BOOKING':
        router.push(`/booking?tab=Upcoming&bid=${refId}`);
        break;
      case 'CHEF_PIN_ON_YOUR_REVIEW':
        redirectUrl !== '' ? router.push(redirectUrl) : router.push(`/`);
        break;

      // Diner - Open Table
      case 'OPEN_TABLE_CANCEL_BOOKING':
        router.push(`/booking?tab=decline&bid=${refId}`);
        break;
      case 'OPEN_TABLE_DINER_CHEF_HAS_CANCELED_BOOKING':
        router.push(`/booking?tab=decline&bid=${refId}`);
        break;
      case 'RECONCILED':
        router.push(`/potioneer-credit`);
        break;

      // Chef
      case 'CHEF_REGISTERED':
        redirectUrl !== '' ? router.push(redirectUrl) : router.push(`/profile/${refId}`);
        break;
      case 'CHEF_BOOKING_CANCELED':
        router.push(`/manage-booking`);
        break;
      case 'DINER_ON_REVIEW':
        redirectUrl !== '' ? router.push(redirectUrl) : router.push(`/`);
        break;
      case 'CHEF_WITHDRAW_REQUEST':
        router.push(`/revenue`);
        break;
      case 'CHEF_WITHDRAW_COMPLETED':
        router.push(`/revenue`);
        break;
      case 'CHEF_PENDING_BOOKING':
        router.push(`/manage-booking`);
        break;
      case 'CHEF_PAYMENT_MADE':
        router.push(`/revenue`);
        break;
      case 'VENUE_REQUEST_ACCEPTED_PLEASE_PUBLISH_COURSE':
        router.push(`/course/${refId}?step=2`);
        break;
      case 'VENUE_REQUEST_ACCEPTED':
        router.push('/profile/0');
        break;
      case 'VENUE_REQUEST_DECLINED':
        router.push(`/venues`);
        break;

      // Chef - Open Table
      case 'OPEN_TABLE_CHEF_PAYMENT_MADE':
        redirectUrl !== '' ? router.push(redirectUrl) : router.push(`/manage-booking`);
        break;
      case 'OPEN_TABLE_CHEF_MIN_GUEST_REACH':
        router.push(`/manage-booking?tab=Upcoming`);
        break;
      case 'OPEN_TABLE_CHEF_CANCEL_BOOKING':
        router.push(`/revenue`);
        break;
      case 'OPEN_TABLE_CHEF_CHEF_HAS_CANCELED_BOOKING':
        router.push(`/revenue`);
        break;

      // Venue
      case 'VENUE_OWNER_REGISTERED':
        router.push(`/venue/${refId}`);
        break;
      case 'VENUE_OWNER_WITHDRAW_REQUEST':
        router.push(`/venue/dashboard`);
        break;
      case 'VENUE_OWNER_WITHDRAW_COMPLETED':
        router.push(`/venue/dashboard`);
        break;
      case 'VENUE_OWNER_PAYMENT_MADE':
        router.push(`/venue/dashboard`);
        break;
      case 'CHEF_REQUEST_TO_USE_VENUE':
        router.push(`/venue/manage-venue`);
        break;
      case 'VENUE_BOOKING':
        router.push(`/venue/manage-venue?tab=calendar`);
        break;

      // Venue - Open Table
      case 'OPEN_TABLE_VENUE_OWNER_CANCEL_BOOKING':
        router.push(`/venue/dashboard`);
        break;
      case 'OPEN_TABLE_VENUE_OWNER_CHEF_HAS_CANCELED_BOOKING':
        router.push(`/venue/dashboard`);
        break;

      //
      case 'MESSAGE':
        router.push('/chat');
        break;
      default:
        break;
    }
    handleMarkAsRead(id);
    markAsRead();
    setIsSubmit(false);
  };

  const stringAvatar = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
      const name = `${firstName[0]}${lastName[0]}`;
      return {
        children: name,
      };
    } else {
      return {
        children: firstName[0],
      };
    }
  };

  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.isUnRead && {
            bgcolor: 'action.selected',
          }),
        }}
        onClick={() => {
          if (isCourseMenu) {
            setIsOpen(true);
            setIdNotification(notification.id);
            setTypeNotification(notification.type);
            setRefIdNotification(notification.refId);
            setRemarkNotification(notification.remark ?? '');
            setRedirectUrlNotification(notification.redirectUrl ?? '');
          } else
            handleOnClickNotification(
              notification.id,
              notification.type,
              notification.remark ?? '',
              notification.redirectUrl ?? '',
              notification.refId
            );
        }}
      >
        <ListItemAvatar sx={{ mt: 0 }}>
          {
            // By system: logo potioneer
            !notificationIconMap[type] ? (
              <Avatar
                sx={{ bgcolor: 'background.neutral' }}
                src="https://images.potioneer.com/PTN_Logo/SVG/PTN_LOGO_ICON-03_FmHJd2Yhb.svg"
              />
            ) : // By user
            avatar ? (
              <Avatar
                sx={{ bgcolor: 'background.neutral' }}
                src={resize_image({
                  url: avatar,
                  width: 40,
                  height: 40,
                })}
                alt={
                  notification.receiverName ? `${notification.receiverName.split(' ')?.[0]}` : ''
                }
              >
                {notification.receiverName ? `${notification.receiverName.split(' ')?.[0]}` : ''}
              </Avatar>
            ) : notification.receiverName ? (
              <Avatar
                {...stringAvatar(
                  notification.receiverName.split(' ')?.[0],
                  notification.receiverName.split(' ')?.[1]
                )}
              />
            ) : (
              <Avatar alt="avatar" />
            )
          }
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              <Iconify icon="eva:clock-fill" sx={{ mr: 0.5, width: 16, height: 16 }} />
              {format(notification.createdAt, 'dd MMM yyyy')}
            </Typography>
          }
        />
      </ListItemButton>

      <Dialog open={isOpen} onClose={onClose} TransitionComponent={Transition}>
        <DialogTitle>
          <Box py={3.5}>
            <Typography variant="h6">Leave this page?</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            You have unsaved changes that will be lost if you decide to continue
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onReasonSubmit}>
            {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={1}>
              <span>Accept</span>
              <CheckCircleRoundedIcon sx={{ fontSize: 18 }} />
            </Stack> */}
            Leave without Saving
          </Button>
          <Button variant="contained" onClick={onClose}>
            Stay on Page
          </Button>
        </DialogActions>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------
function renderContent(notification: NotificationItemProps) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component={notificationDescriptionMap[notification.type] ? 'p' : 'span'}
        variant="body2"
        sx={{ color: 'text.secondary' }}
      >
        {notificationDescriptionMap[notification.type] ? '' : ' '} {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
