import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useEffect, useState } from 'react';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import useScreen from 'src/hooks/useScreen';
import { Province } from 'src/services/province';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CreateChefForm } from './CreateChefModal';

const locationschema = yup
  .object({
    titleLocation: yup.string().required().label('title location'),
    typeOfLocation: yup.string().required().label('type of location'),
    baseId: yup.string().required().label('based in'),
  })
  .required();

interface ChefLocationProps {
  provinces: Province[];
  isMobile?: boolean;
  onBack: () => void;
  onNext: () => void;
  formValue: CreateChefForm;
  onFormSubmit: (title: string, type: string, baseId: number) => void;
}

interface ChefLocationForm {
  titleLocation: string;
  typeOfLocation: string;
  baseId?: string;
}

const ChefLocation: FunctionComponent<ChefLocationProps> = ({
  provinces,
  isMobile,
  onBack,
  onNext,
  formValue,
  onFormSubmit,
}) => {
  const { height } = useScreen(100);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<ChefLocationForm>({
    resolver: yupResolver(locationschema),
    defaultValues: {
      titleLocation: formValue.titleLocation,
      typeOfLocation: formValue.typeOfLocation,
      baseId: `${formValue.baseId}`,
    },
  });

  const onSubmit = (data: ChefLocationForm) => {
    onFormSubmit(data.titleLocation, data.typeOfLocation, parseInt(data.baseId || ''));
    onNext();
  };

  const onClickBack = () => {
    const data = getValues();

    onFormSubmit(data.titleLocation, data.typeOfLocation, parseInt(data.baseId || ''));

    onBack();
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      component="form"
      height={isMobile ? height : '750px'}
      paddingTop={6}
      paddingBottom={{ xs: 5, md: 3 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item>
        <Box marginBottom={4}>
          <Typography variant="h4">Let diners know where to</Typography>
          <Typography variant="h4" gutterBottom>
            find you!
          </Typography>
        </Box>
        <Controller
          control={control}
          name="titleLocation"
          render={({ field }) => (
            <FormControl sx={{ mb: 2, width: '100%' }} variant="outlined">
              <TextField
                id="title"
                placeholder="Title e.g. Sous chef or Private chef"
                fullWidth
                {...field}
                error={!!errors.titleLocation}
                helperText={errors.titleLocation?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="typeOfLocation"
          render={({ field }) => (
            <FormControl sx={{ mb: 2, width: '100%' }} variant="outlined">
              <TextField
                id="restaurant-title"
                placeholder="Company /Restaurant"
                fullWidth
                {...field}
                error={!!errors.typeOfLocation}
                helperText={errors.typeOfLocation?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="baseId"
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <InputLabel id="chef-base-in-label">Based in</InputLabel>
                <Select
                  id="chef-base-in"
                  labelId="chef-base-in-label"
                  label="Based in"
                  placeholder="Based in"
                  {...field}
                  error={!!errors.baseId}
                >
                  {provinces.map((province) => (
                    <MenuItem key={province.id} value={province.id}>
                      {province.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{errors.baseId?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </Grid>
      <Grid item>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button type="button" fullWidth variant="outlined" size="large" onClick={onClickBack}>
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button type="submit" fullWidth variant="contained" size="large">
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChefLocation;
