import { get, post } from '@utils/fetch';

export interface CuisineType {
  id: number;
  name: string;
  isActive: boolean;
}

export async function get_cuisine_type() {
  return await get<CuisineType[]>(`${process.env.NEXT_PUBLIC_API_URL}/data/type-of-cuisine`);
}

export function create_cuisine_type(data: { name: string }) {
  return post<CuisineType[]>(`${process.env.NEXT_PUBLIC_API_URL}/data/type-of-cuisine`, data);
}
