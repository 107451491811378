import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Stack from '@mui/material/Stack';
import { reset_password_with_email } from 'src/services/auth';

interface ResetPasswordProps {
  onSuccess: (email: string) => void;
}
interface ResetPasswordForm {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const ResetPassword: FunctionComponent<ResetPasswordProps> = ({ onSuccess }) => {
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<string>('');

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data: ResetPasswordForm) => {
    reset_password_with_email(data.email).then((res) => {
      if (res?.code !== '200') {
        setError('email', {
          message: `This email you entered doesn't exist. Please check your spelling or try another one.`,
        });
        setIsEmail(data.email);
        setIsDisabledButton(true);
        return;
      }

      setIsEmail('');
      setIsDisabledButton(false);
      onSuccess(data.email);
    });
  };

  return (
    <Container maxWidth="sm">
      <Stack py={3} spacing={2}>
        <Typography variant="h3">Reset password</Typography>
        <Typography variant="body2">
          We will send you a link to reset the password to your email below.
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                id="change-email"
                label="Email"
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...field}
                onChange={(e) => {
                  if (e.target.value === isEmail) setIsDisabledButton(true);
                  else setIsDisabledButton(false);

                  field.onChange(e);
                }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            disabled={isDisabledButton}
          >
            Send
          </Button>
        </Box>
      </Stack>
    </Container>
  );
};

export default ResetPassword;
