import { CoursesData } from '@services/course_public';
import type { Location } from '@typings/course';
import { get, post } from '@utils/fetch';
import { CuisineType } from './cuisine_type';
import { Image } from './upload';
export interface GetSuggestionsData {
  chefs: [
    {
      id: number;
      title: string;
    }
  ];
  courses: [
    {
      id: number;
      title: string;
    }
  ];
}

export async function get_search_suggestions(
  keyword: string,
  options?: Pick<RequestInit, 'signal'>
) {
  return await get<GetSuggestionsData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/search/suggest?keyword=${keyword}`,
    undefined,
    options
  );
}

export async function get_recent_keyword(token: string) {
  return await get<string[]>(`${process.env.NEXT_PUBLIC_API_URL}/recent-keyword`, {
    Authorization: `Bearer ${token}`,
  });
}

export async function save_recent_keyword(token: string, keyword: string | undefined) {
  return await post(
    `${process.env.NEXT_PUBLIC_API_URL}/recent-keyword?keyword=${keyword}`,
    {},
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export interface GetSearchCoursesResponse {
  total: number;
  data: CoursesData[];
}

export interface SearchQuery {
  keyword?: string;
  startTime?: string;
  endTime?: string;
  location?: string;
  minPrice?: string;
  maxPrice?: string;
  minGuests?: string;
  maxGuests?: string;
  preferredLocation?: 'CHEF' | 'YOUR_LOCATION';
}

export async function get_search_courses(
  page: number,
  limit: number,
  searchQuery: SearchQuery,
  sort?: string
) {
  console.log('searchQuery', searchQuery);

  const {
    keyword,
    startTime,
    endTime,
    location,
    minPrice,
    maxPrice,
    preferredLocation,
    minGuests,
    maxGuests,
  } = searchQuery;
  const url = new URLSearchParams();
  keyword && url.set('keyword', keyword ?? '');
  startTime && url.set('startTime', startTime ?? '');
  endTime && url.set('endTime', endTime ?? '');
  location && url.set('location', location ?? '');
  minPrice && url.set('minPrice', minPrice ?? '');
  maxPrice && url.set('maxPrice', maxPrice ?? '');
  preferredLocation && url.set('preferredLocation', preferredLocation ?? '');
  minGuests && url.set('minGuests', minGuests ?? '');
  maxGuests && url.set('maxGuests', maxGuests ?? '');
  url.set('limit', limit?.toString() ?? '');
  url.set('page', page?.toString() ?? '');
  return await get<GetSearchCoursesResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v3/search/course?${url.toString()}`
  );
}

export interface GetSearchChefs {
  id: number;
  name: string;
  imageProfile: Image;
  typeOfCuisines: CuisineType[];
}
export interface GetSearchChefsResponse {
  total: number;
  data: GetSearchChefs[];
}

export async function get_search_chefs(page: number, limit: number, searchQuery: SearchQuery) {
  const {
    keyword,
    startTime,
    endTime,
    location,
    minPrice,
    maxPrice,
    preferredLocation,
    maxGuests,
    minGuests,
  } = searchQuery;
  const url = new URLSearchParams();
  keyword && url.set('keyword', keyword ?? '');
  startTime && url.set('startTime', startTime ?? '');
  endTime && url.set('endTime', endTime ?? '');
  location && url.set('location', location ?? '');
  minPrice && url.set('minPrice', minPrice ?? '');
  maxPrice && url.set('maxPrice', maxPrice ?? '');
  minGuests && url.set('minGuests', minGuests ?? '');
  maxGuests && url.set('maxGuests', maxGuests ?? '');
  preferredLocation && url.set('preferredLocation', preferredLocation ?? '');
  url.set('limit', limit?.toString() ?? '');
  url.set('page', page?.toString() ?? '');

  return await get<GetSearchChefsResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/search/chef?${url.toString()}`
  );
}

export interface SearchVenue {
  isPotioneerLocation?: boolean;
  keyword?: string;
  limit: number;
  maxGuests?: number;
  maxPrice?: number;
  minGuests?: number;
  minPrice?: number;
  page: number;
  sortBy?: string;
}

export interface SearchVenueResponse {
  total: number;
  data: Venue[];
}

export interface Venue {
  venueId: number | string;
  venueName: string;
  location: Location;
  totalRentalFee: number;
  minCapacity: number;
  maxCapacity: number;
  isParkingAvailable: boolean;
  IsPotioneerLocation: boolean;
  locationRemarks: string;
  imageAmbiences: ImageAmbience[];
}

export interface ImageAmbience {
  id: number;
  imageUrl: string;
  thumbnailUrl: string;
}

export async function search_venue(query: SearchVenue) {
  return await post<SearchVenueResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/search/venue`,
    query
  );
}

export interface VenueSuggest {
  suggestField: string;
  suggestValue: string;
}

export async function get_search_venue_suggest(keyword: string) {
  return await get<VenueSuggest[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/search/venue/suggest?keyword=${keyword}`
  );
}
