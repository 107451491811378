import { searchbarOnKeyboardAtom } from '@atoms/searchbar';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { PaperProps } from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { resize_image } from '@utils/image';
import startOfToday from 'date-fns/startOfToday';
import dayjs from 'dayjs';
import { Dispatch, FunctionComponent, SetStateAction, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { popularCuisineOptions, priceOptions, timeOptions, venueOptions } from './fieldOptions';
export interface Suggestion {
  id: number;
  title: string;
  type: string;
  label: string;
}

export interface FieldOption {
  label: string;
  value: string;
}

const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  '&.Mui-selected': {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    color: 'white',
  },
  '&.MuiPickersDay-today': {
    border: '1px solid rgba(145, 158, 171, 0.24)',
    borderRadius: '50px',
    color: '#919EAB !important',
    '&:not(.Mui-selected)': {
      backgroundColor: 'rgba(145, 158, 171, 0.16)',
    },
  },
  '&.Mui-disabled': {
    color: '#DFE3E8',
  },
})) as React.ComponentType<PickersDayProps<Date>>;

const WhenDatePicker = styled(StaticDatePicker)`
  .MuiDayPicker-weekDayLabel,
  .MuiPickersDay-root {
    margin: 0 7px;
    font-size: 14px;
  }
  .MuiCalendarOrClockPicker-root {
    & > div {
      width: 390px;
    }
  }
  .MuiCalendarPicker-root {
    width: 390px;
  }
` as typeof StaticDatePicker;

const SearchBarContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  border: ' 1px solid rgba(145, 158, 171, 0.24)',
  padding: theme.spacing(1, 2, 1, 3),
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.white,
}));

const SearchFieldContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2, 1, 3),

  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#fafafa',
    borderRadius: theme.spacing(5),
  },
}));

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  margin: theme.spacing(0.75, 0, 0.75, 1.75),
  height: '50px',
  width: '50px',
  '&:hover': {
    background: '#092321',
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(1.125, 1),
}));

export const PopoverContentTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(3.625),
}));

export const PopOverContentItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  '&:hover': {
    background: '#fafafa',
    cursor: 'pointer',
  },
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'white',
    padding: 0,
    borderRadius: theme.spacing(5),
    marginLeft: theme.spacing(-6),
    boxShadow: theme.customShadows.z20,
    border: '1px solid rgba(145, 158, 171, 0.24)',
    minWidth: 396,
  },
}));

export interface SearchBarV3DesktopViewProps {
  setWhatToEatVal: (values: { value: string | undefined; isPopup?: boolean }) => void;
  setVenueVal: Dispatch<SetStateAction<FieldOption | undefined>>;
  setTimeVal: Dispatch<SetStateAction<FieldOption | undefined>>;
  setDateVal: Dispatch<SetStateAction<Date | undefined>>;
  setGuestVal: Dispatch<SetStateAction<number>>;
  setPriceVal: Dispatch<SetStateAction<FieldOption | undefined>>;
  setShouldShowSuggestion: Dispatch<SetStateAction<boolean>>;
  setIswhatToEatPopoverOpen: Dispatch<SetStateAction<boolean>>;
  setIsRecentKeywordPopoverOpen: Dispatch<SetStateAction<boolean>>;
  isWhatToEatPopoverOpen: boolean;
  suggestionsVal: Suggestion[];
  recentKeywordVal: string[];
  isRecentKeywordPopoverOpen: boolean;
  whatToEatVal: string;
  venueVal: FieldOption | undefined;
  timeVal: FieldOption | undefined;
  dateVal: Date | undefined;
  guestVal: number;
  priceVal: FieldOption | undefined;
  onSearch: VoidFunction;
}

const popOverPaperProps: PaperProps = {
  sx: {
    ml: -3.25,
    mt: 3.375,
    border: '1px solid rgba(145, 158, 171, 0.24)',
    borderRadius: (theme) => theme.spacing(5),
  },
};

const SearchBarV3DesktopView: FunctionComponent<SearchBarV3DesktopViewProps> = (props) => {
  const {
    setWhatToEatVal,
    setVenueVal,
    setTimeVal,
    setDateVal,
    setGuestVal,
    setPriceVal,
    setShouldShowSuggestion,
    setIswhatToEatPopoverOpen,
    setIsRecentKeywordPopoverOpen,
    isWhatToEatPopoverOpen,
    suggestionsVal,
    recentKeywordVal,
    isRecentKeywordPopoverOpen,
    venueVal,
    timeVal,
    dateVal,
    guestVal,
    priceVal,
    whatToEatVal,
    onSearch,
  } = props;

  const [isVenuePopoverOpen, setIsVenuePopoverOpen] = useState(false);
  const [isTimePopoverOpen, setIsTimepopoverOpen] = useState(false);
  const [isGuestPopoverOpen, setIsGuestPopoverOpen] = useState(false);
  const [isPricePopoverOpen, setIsPricepopoverOpen] = useState(false);

  const renderWhatToEatPopoverContent = useMemo(
    () => (
      <ClickAwayListener onClickAway={() => setIswhatToEatPopoverOpen(false)}>
        <Box sx={{ py: 3, minWidth: 394 }}>
          <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
            Trending
          </PopoverContentTitle>
          {suggestionsVal.map((suggestion) => (
            <PopOverContentItem
              key={suggestion.id}
              onClick={() => {
                setWhatToEatVal({ value: suggestion.title, isPopup: true });
                setIswhatToEatPopoverOpen(false);
                setShouldShowSuggestion(false);
              }}
            >
              <Typography variant="body1" sx={{ color: 'black' }}>
                {suggestion.title}
              </Typography>
            </PopOverContentItem>
          ))}
        </Box>
      </ClickAwayListener>
    ),
    [setIswhatToEatPopoverOpen, setShouldShowSuggestion, setWhatToEatVal, suggestionsVal]
  );

  const renderRecentKeywordPopoverContent = useMemo(
    () => (
      <ClickAwayListener onClickAway={() => setIsRecentKeywordPopoverOpen(false)}>
        <Box sx={{ py: 3, minWidth: 394 }}>
          {recentKeywordVal.length > 0 && (
            <>
              <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
                Your recent searches
              </PopoverContentTitle>
              {recentKeywordVal.map((recent) => (
                <PopOverContentItem
                  key={recent}
                  onClick={() => {
                    setWhatToEatVal({ value: recent, isPopup: true });
                    setIsRecentKeywordPopoverOpen(false);
                    setShouldShowSuggestion(false);
                  }}
                >
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    {recent}
                  </Typography>
                </PopOverContentItem>
              ))}
              <br />
            </>
          )}
          <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
            Popular cuisine
          </PopoverContentTitle>
          {popularCuisineOptions.map((option) => (
            <PopOverContentItem
              sx={{ color: 'black' }}
              key={option.value}
              onClick={() => {
                setWhatToEatVal({ value: option.value, isPopup: true });
                setIsRecentKeywordPopoverOpen(false);
                setShouldShowSuggestion(false);
              }}
            >
              <Typography variant="body1">{option.label}</Typography>
            </PopOverContentItem>
          ))}
        </Box>
      </ClickAwayListener>
    ),
    [setIsRecentKeywordPopoverOpen, setShouldShowSuggestion, setWhatToEatVal, recentKeywordVal]
  );

  const VenuePopoverContent = () => (
    <Box sx={{ py: 3, minWidth: 394 }}>
      <PopoverContentTitle variant="subtitle1">Dine at...</PopoverContentTitle>
      {venueOptions.map((option) => (
        <PopOverContentItem
          key={option.value}
          onClick={() => {
            setVenueVal(option);
            setIsVenuePopoverOpen(false);
          }}
        >
          <Typography variant="body1">{option.label}</Typography>
        </PopOverContentItem>
      ))}
    </Box>
  );

  const onDateChange = (value: Date | null) => {
    if (!value) return;
    setDateVal(value);
  };

  const DateTimePopoverContent = () => (
    <Box sx={{ py: 3, minWidth: 378 }}>
      <Typography ml={3} variant="subtitle1">
        Choose time
      </Typography>
      <Box
        ml={3}
        mr={3}
        mt={1}
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="row" spacing={1}>
          {timeOptions.map((option) => {
            return (
              <Chip
                sx={{
                  '&.actived': {
                    backgroundColor: 'primary.main',
                    color: 'white',
                  },
                }}
                label={option.label}
                key={option.value}
                className={timeVal?.value === option.value ? 'actived' : ''}
                onClick={() => setTimeVal(option)}
              />
            );
          })}
        </Stack>
      </Box>

      <Typography ml={3} variant="subtitle1">
        Choose date
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <WhenDatePicker
          openTo="day"
          shouldDisableDate={(date) => date < startOfToday()}
          displayStaticWrapperAs="desktop"
          value={dateVal}
          onChange={onDateChange}
          renderInput={(params) => <TextField {...params} />}
          dayOfWeekFormatter={(day) => `${day}.`}
          renderDay={(day, _value, DayComponentProps) => {
            return <StyledPickersDay {...DayComponentProps} />;
          }}
          className="desktop-search-input-when"
        />
      </LocalizationProvider>

      <Box ml={3} mr={3} display="flex" justifyContent="space-between" alignItems="center">
        <Button
          onClick={() => {
            setDateVal(undefined);
            setTimeVal(undefined);
          }}
        >
          Clear
        </Button>
        <Button variant="outlined" onClick={() => setIsTimepopoverOpen(false)}>
          Apply
        </Button>
      </Box>
    </Box>
  );

  const GuestPopoverContent = () => (
    <Box sx={{ py: 3, maxWidth: 333 }}>
      <PopoverContentTitle variant="subtitle1">How many ?</PopoverContentTitle>
      <Box ml={4} mr={4} mt={1} mb={2} display="flex" alignItems="center">
        <Grid item xs={8}>
          <Typography fontSize={14}>No. of guest</Typography>
        </Grid>

        <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
          {guestVal === 0 && (
            <IconButton>
              <img src="/icons/ic_minus_inactive.svg" alt="minus icon" />
            </IconButton>
          )}
          {guestVal > 0 && (
            <IconButton onClick={() => setGuestVal(guestVal - 1)}>
              <img src="/icons/ic_minus.svg" alt="minus icon" />
            </IconButton>
          )}

          <TextField
            size="small"
            InputProps={{ inputProps: { style: { textAlign: 'center' } } }}
            color="primary"
            value={guestVal}
            disabled={true}
          />

          {guestVal === 20 && (
            <IconButton>
              <img src="/icons/ic_plus_inactive.svg" alt="plus icon" />
            </IconButton>
          )}
          {guestVal < 20 && (
            <IconButton onClick={() => setGuestVal(guestVal + 1)}>
              <img src="/icons/ic_plus.svg" alt="plus icon" />
            </IconButton>
          )}
        </Stack>
      </Box>

      {guestVal > 0 && (
        <Box ml={4} mr={4} display="flex" justifyContent="center" alignItems="center">
          <Button onClick={() => setGuestVal(0)}>Clear</Button>
        </Box>
      )}
    </Box>
  );

  const PricePopoverContent = () => (
    <Box sx={{ py: 3, minWidth: 378 }}>
      <PopoverContentTitle variant="subtitle1">Price range per head</PopoverContentTitle>
      {priceOptions.map((option) => (
        <PopOverContentItem
          key={option.value}
          onClick={() => {
            setPriceVal(option);
            setIsPricepopoverOpen(false);
          }}
        >
          <Typography variant="body1">{option.label}</Typography>
        </PopOverContentItem>
      ))}
    </Box>
  );

  const getDateTimePlaceHolder = (timeVal: FieldOption | undefined, dateVal: Date | undefined) => {
    if (timeVal && dateVal) {
      return `${timeVal.label}, ${dayjs(dateVal).format('DD MMM')}`;
    } else if (timeVal) {
      return timeVal.label;
    } else if (!timeVal && dateVal) {
      return `Any, ${dayjs(dateVal).format('DD MMM')}`;
    }
    return 'Choose date';
  };

  return (
    <Grid container justifyContent="center" sx={{ paddingBottom: 4, marginTop: 3 }}>
      <SearchBarContainer>
        {/* What to eat field */}
        <Box>
          <HtmlTooltip
            open={isWhatToEatPopoverOpen || isRecentKeywordPopoverOpen}
            title={whatToEatVal ? renderWhatToEatPopoverContent : renderRecentKeywordPopoverContent}
            placement="bottom-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            PopperProps={{
              anchorEl:
                typeof window === 'object'
                  ? document.getElementById('what-to-eat-field-input')
                  : null,
              placement: 'bottom-start',
              disablePortal: true,
              style: { marginTop: '24px' },
            }}
          >
            <SearchFieldContainer>
              <Typography variant="subtitle1">What Are You Looking For?</Typography>
              <TextField
                fullWidth
                id="what-to-eat-field-input"
                variant="standard"
                autoComplete="off"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: '14px' },
                }}
                // inputProps={{ style: { padding: 0, minWidth: 175 } }}
                inputProps={{ style: { padding: 0 } }}
                placeholder="Chef, cuisine, or keywords"
                size="small"
                value={whatToEatVal}
                onChange={(e) => {
                  setWhatToEatVal({ value: e.target.value });
                  setShouldShowSuggestion(true);
                  setIsRecentKeywordPopoverOpen(e.target.value ? false : true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearch();
                  }
                }}
                onClick={() => setIsRecentKeywordPopoverOpen(whatToEatVal ? false : true)}
              />
            </SearchFieldContainer>
          </HtmlTooltip>
        </Box>
        {/* What to eat field */}
        <StyledDivider flexItem orientation="vertical" />
        {/* venue field */}
        <Box>
          <SearchFieldContainer onClick={() => setIsVenuePopoverOpen(true)}>
            <Typography variant="subtitle1">Where?</Typography>
            <Typography
              variant="body2"
              sx={{ color: venueVal ? 'none' : '#9D9E9F' }}
              id="venue-field-input"
            >
              {venueVal ? venueVal.label : 'Choose venue'}
            </Typography>
          </SearchFieldContainer>
          <Popover
            open={isVenuePopoverOpen}
            anchorEl={
              typeof window === 'object' ? document.getElementById('venue-field-input') : null
            }
            onClose={() => setIsVenuePopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={popOverPaperProps}
          >
            <VenuePopoverContent />
          </Popover>
        </Box>
        {/* venue field */}
        <StyledDivider flexItem orientation="vertical" />
        {/* date time field */}
        <Box>
          <SearchFieldContainer onClick={() => setIsTimepopoverOpen(true)}>
            <Typography variant="subtitle1">When?</Typography>
            <Typography
              variant="body2"
              sx={{ color: timeVal || dateVal ? 'none' : '#9D9E9F' }}
              id="time-field-input"
            >
              {getDateTimePlaceHolder(timeVal, dateVal)}
            </Typography>
          </SearchFieldContainer>
          <Popover
            open={isTimePopoverOpen}
            anchorEl={
              typeof window === 'object' ? document.getElementById('time-field-input') : null
            }
            onClose={() => setIsTimepopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={popOverPaperProps}
          >
            <DateTimePopoverContent />
          </Popover>
        </Box>
        {/* date time field */}
        <StyledDivider flexItem orientation="vertical" />
        {/* guest field */}
        <Box>
          <SearchFieldContainer onClick={() => setIsGuestPopoverOpen(true)}>
            <Typography variant="subtitle1">Guest</Typography>
            <Typography
              variant="body2"
              sx={{ color: guestVal ? 'none' : '#9D9E9F' }}
              id="guest-field-input"
            >
              {guestVal > 0 ? `${guestVal} guest` : 'Any'}
            </Typography>
          </SearchFieldContainer>
          <Popover
            open={isGuestPopoverOpen}
            anchorEl={
              typeof window === 'object' ? document.getElementById('guest-field-input') : null
            }
            onClose={() => setIsGuestPopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              ...popOverPaperProps,
              sx: {
                ...popOverPaperProps.sx,
                ml: -20,
              },
            }}
          >
            <GuestPopoverContent />
          </Popover>
        </Box>

        {/* guest field */}
        <StyledDivider flexItem orientation="vertical" />
        {/* price field */}
        <Box>
          <SearchFieldContainer onClick={() => setIsPricepopoverOpen(true)}>
            <Typography variant="subtitle1">Price</Typography>
            <Typography
              variant="body2"
              sx={{ color: priceVal ? 'none' : '#9D9E9F' }}
              id="price-field-input"
            >
              {priceVal ? priceVal.label : 'Any'}
            </Typography>
          </SearchFieldContainer>
          <Popover
            open={isPricePopoverOpen}
            anchorEl={
              typeof window === 'object' ? document.getElementById('price-field-input') : null
            }
            onClose={() => setIsPricepopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              ...popOverPaperProps,
              sx: {
                ...popOverPaperProps.sx,
                ml: -20,
              },
            }}
          >
            <PricePopoverContent />
          </Popover>
        </Box>
        {/* price field */}
        <SearchIconButton id="SearchBar" onClick={onSearch}>
          <img
            src={resize_image({
              url: 'https://images.potioneer.com/images-landing/icon_search_tJtmS2z0j.svg',
              width: 26,
              height: 26,
            })}
            alt="search"
          />
        </SearchIconButton>
      </SearchBarContainer>
    </Grid>
  );
};

export default SearchBarV3DesktopView;
