import { searchbarOnKeyboardAtom } from '@atoms/searchbar';
import { DialogAnimate } from '@components/animate';
import Modal from '@components/Modal';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Button, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { PaperProps } from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { resize_image } from '@utils/image';
import { startOfToday } from 'date-fns';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { popularCuisineOptions, priceOptions, timeOptions, venueOptions } from './fieldOptions';
import {
  PopOverContentItem,
  PopoverContentTitle,
  SearchBarV3DesktopViewProps,
} from './SearchBarV3DesktopView';
import { useHideFacebookPopup } from '@hooks/useHideFacebookPopup';

const SearchInputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 0.5, 1.5),
}));

const SearchBarContainer = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(5),
  border: ' 1px solid rgba(145, 158, 171, 0.24)',
  padding: theme.spacing(0.5, 1, 0.5, 1.5),
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.white,
  width: '100%',
}));

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.primary.main,
  margin: theme.spacing(0.125, 0, 0.125, 0),
  height: '32px',
  width: '32px',
}));

const SearchFilterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 0.5, 1.5),
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(145, 158, 171, 0.24)',
  background: theme.palette.common.white,
  borderRadius: theme.spacing(5),
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'transparent',
    padding: 0,
    borderRadius: theme.spacing(5),
    // marginLeft: theme.spacing(-6),
    boxShadow: theme.customShadows.z20,
    minWidth: '346px',
  },
}));

const WhenDatePicker = styled(StaticDatePicker)`
  .MuiDayPicker-weekDayLabel,
  .MuiPickersDay-root {
    margin: 0 4px;
    font-size: 14px;
  }
  .MuiCalendarOrClockPicker-root {
    & > div {
      width: 380px;
    }
  }
  .MuiCalendarPicker-root {
    width: 380px;
  }
` as typeof StaticDatePicker;

const SearchBarV3MobileView: FunctionComponent<SearchBarV3DesktopViewProps> = (props) => {
  const {
    setWhatToEatVal,
    setVenueVal,
    setTimeVal,
    setDateVal,
    setGuestVal,
    setPriceVal,
    setShouldShowSuggestion,
    setIswhatToEatPopoverOpen,
    setIsRecentKeywordPopoverOpen,
    isWhatToEatPopoverOpen,
    suggestionsVal,
    recentKeywordVal,
    isRecentKeywordPopoverOpen,
    venueVal,
    timeVal,
    dateVal,
    guestVal,
    priceVal,
    whatToEatVal,
    onSearch,
  } = props;

  const [searchref, setSearchRef] = useState<HTMLSpanElement | null>(null);
  const [isVenuePopoverOpen, setIsVenuePopoverOpen] = useState(false);
  const [isTimePopoverOpen, setIsTimepopoverOpen] = useState(false);
  const [isGuestPopoverOpen, setIsGuestPopoverOpen] = useState(false);
  const [isPricePopoverOpen, setIsPricepopoverOpen] = useState(false);

  const [searchbarOnKeyboardState, setSearchbarOnKeyboardState] =
    useRecoilState(searchbarOnKeyboardAtom);

  useHideFacebookPopup(isGuestPopoverOpen || isTimePopoverOpen);

  const renderWhatToEatPopoverContent = useMemo(
    () => (
      <ClickAwayListener
        onClickAway={() => {
          setIswhatToEatPopoverOpen(false);
          setSearchRef(null);
        }}
      >
        <Box
          sx={{
            py: 3,
            minWidth: '343px',
            background: 'white',
            borderRadius: (theme) => theme.spacing(5),
            border: '1px solid rgba(145, 158, 171, 0.24)',
            boxShadow: (theme) => theme.customShadows.z20,
          }}
        >
          <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
            Trending
          </PopoverContentTitle>
          {suggestionsVal.map((suggestion) => (
            <PopOverContentItem
              key={suggestion.id}
              onClick={() => {
                setWhatToEatVal({ value: suggestion.title, isPopup: true });
                setIswhatToEatPopoverOpen(false);
                setShouldShowSuggestion(false);
                setSearchRef(null);
              }}
            >
              <Typography variant="body1" sx={{ color: 'black' }}>
                {suggestion.title}
              </Typography>
            </PopOverContentItem>
          ))}
        </Box>
      </ClickAwayListener>
    ),
    [setIswhatToEatPopoverOpen, setShouldShowSuggestion, setWhatToEatVal, suggestionsVal]
  );

  const renderRecentKeywordPopoverContent = useMemo(
    () => (
      <ClickAwayListener onClickAway={() => setIsRecentKeywordPopoverOpen(false)}>
        <Box
          sx={{
            py: 3,
            minWidth: '343px',
            background: 'white',
            borderRadius: (theme) => theme.spacing(5),
            border: '1px solid rgba(145, 158, 171, 0.24)',
            boxShadow: (theme) => theme.customShadows.z20,
          }}
        >
          {recentKeywordVal.length > 0 && (
            <>
              <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
                Your recent searches
              </PopoverContentTitle>
              {recentKeywordVal.map((recent) => (
                <PopOverContentItem
                  key={recent}
                  onClick={() => {
                    setWhatToEatVal({ value: recent, isPopup: true });
                    setIsRecentKeywordPopoverOpen(false);
                    setShouldShowSuggestion(false);
                  }}
                >
                  <Typography variant="body1" sx={{ color: 'black' }}>
                    {recent}
                  </Typography>
                </PopOverContentItem>
              ))}
              <br />
            </>
          )}
          <PopoverContentTitle variant="subtitle1" sx={{ color: 'black' }}>
            Popular cuisine
          </PopoverContentTitle>
          {popularCuisineOptions.map((option) => (
            <PopOverContentItem
              sx={{ color: 'black' }}
              key={option.value}
              onClick={() => {
                setWhatToEatVal({ value: option.value, isPopup: true });
                setIsRecentKeywordPopoverOpen(false);
                setShouldShowSuggestion(false);
              }}
            >
              <Typography variant="body1">{option.label}</Typography>
            </PopOverContentItem>
          ))}
        </Box>
      </ClickAwayListener>
    ),
    [setIsRecentKeywordPopoverOpen, setShouldShowSuggestion, setWhatToEatVal, recentKeywordVal]
  );

  const popOverPaperProps: PaperProps = {
    sx: {
      mt: 3.375,
      border: '1px solid rgba(145, 158, 171, 0.24)',
      borderRadius: (theme) => theme.spacing(5),
    },
  };

  const filterPopoverPaperProps: PaperProps = useMemo(() => {
    return {
      sx: {
        mt: 1,
      },
    };
  }, []);

  const VenuePopoverContent = useMemo(
    () => () =>
      (
        <Box sx={{ py: 3, maxWidth: 300 }}>
          <PopoverContentTitle variant="subtitle1">Dine at...</PopoverContentTitle>
          {venueOptions.map((option) => (
            <PopOverContentItem
              key={option.value}
              onClick={() => {
                setVenueVal(option);
                setIsVenuePopoverOpen(false);
              }}
            >
              <Typography variant="body1">{option.label}</Typography>
            </PopOverContentItem>
          ))}
        </Box>
      ),
    [setVenueVal]
  );

  const onDateChange = useCallback(
    (value: Date | null) => {
      if (!value) return;
      setDateVal(value);
    },
    [setDateVal]
  );

  const DateTimePopoverContent = useMemo(
    () => () =>
      (
        <Box sx={{ py: 3, minWidth: 378 }}>
          <Typography ml={3} variant="subtitle1">
            Choose time
          </Typography>
          <Box
            ml={3}
            mr={3}
            mt={1}
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={1} sx={{ overflow: 'scroll' }}>
              {timeOptions.map((option) => {
                return (
                  <Chip
                    sx={{
                      '&.actived': {
                        backgroundColor: 'primary.main',
                        color: 'white',
                      },
                    }}
                    label={option.label}
                    key={option.value}
                    className={timeVal?.value === option.value ? 'actived' : ''}
                    onClick={() => setTimeVal(option)}
                  />
                );
              })}
            </Stack>
          </Box>

          <Typography ml={3} variant="subtitle1">
            Choose date
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <WhenDatePicker
              shouldDisableDate={(date) => date < startOfToday()}
              displayStaticWrapperAs="desktop"
              value={dateVal}
              onChange={onDateChange}
              renderInput={(params) => <TextField {...params} />}
              dayOfWeekFormatter={(day) => `${day}.`}
              toolbarFormat="ddd DD MMMM"
            />
          </LocalizationProvider>

          <Box ml={3} mr={3} display="flex" justifyContent="space-between" alignItems="center">
            <Button
              onClick={() => {
                setDateVal(undefined);
                setTimeVal(undefined);
              }}
            >
              Clear
            </Button>
            <Button variant="outlined" onClick={() => setIsTimepopoverOpen(false)}>
              Apply
            </Button>
          </Box>
        </Box>
      ),
    [setDateVal, setTimeVal, dateVal, timeVal, onDateChange]
  );

  const GuestPopoverContent = useMemo(
    () => () => {
      const disabledClearButton = guestVal <= 0;
      return (
        <Box sx={{ py: 3, pr: 3 }}>
          <PopoverContentTitle variant="subtitle1">How many ?</PopoverContentTitle>
          <Box ml={4} mt={1} mb={2} display="flex" alignItems="center">
            <Grid item xs={8}>
              <Typography fontSize={14}>No. of guest</Typography>
            </Grid>

            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
              {guestVal === 0 && (
                <IconButton>
                  <img src="/icons/ic_minus_inactive.svg" alt="minus icon" />
                </IconButton>
              )}
              {guestVal > 0 && (
                <IconButton onClick={() => setGuestVal(guestVal - 1)}>
                  <img src="/icons/ic_minus.svg" alt="minus icon" />
                </IconButton>
              )}

              <TextField
                size="small"
                InputProps={{ inputProps: { style: { textAlign: 'center' } } }}
                color="primary"
                value={guestVal}
                disabled={true}
              />

              {guestVal === 20 && (
                <IconButton>
                  <img src="/icons/ic_plus_inactive.svg" alt="plus icon" />
                </IconButton>
              )}
              {guestVal < 20 && (
                <IconButton onClick={() => setGuestVal(guestVal + 1)}>
                  <img src="/icons/ic_plus.svg" alt="plus icon" />
                </IconButton>
              )}
            </Stack>
          </Box>

          <Box ml={3} display="flex" justifyContent="space-between" alignItems="center">
            <Button
              onClick={() => {
                setGuestVal(0);
              }}
              disabled={disabledClearButton}
              sx={{ visibility: disabledClearButton ? 'hidden' : 'visible' }}
            >
              Clear
            </Button>
            <Button variant="outlined" onClick={() => setIsGuestPopoverOpen(false)}>
              Apply
            </Button>
          </Box>
        </Box>
      );
    },
    [setGuestVal, guestVal]
  );

  const PricePopoverContent = useMemo(
    () => () =>
      (
        <Box sx={{ py: 3, minWidth: 224 }}>
          <PopoverContentTitle variant="subtitle1">Price range per head</PopoverContentTitle>
          {priceOptions.map((option) => (
            <PopOverContentItem
              key={option.value}
              onClick={() => {
                setPriceVal(option);
                setIsPricepopoverOpen(false);
              }}
            >
              <Typography variant="body1">{option.label}</Typography>
            </PopOverContentItem>
          ))}
        </Box>
      ),
    [setPriceVal]
  );

  const renderSearchFilter = useMemo(
    () => (
      <Grid container justifyContent="center" marginTop={1.25}>
        <Stack
          spacing={0.5}
          direction="row"
          overflow="auto"
          sx={{
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {/* venue filter */}
          <Box>
            <SearchFilterContainer id="venue-filter" onClick={() => setIsVenuePopoverOpen(true)}>
              <Typography
                variant="body2"
                mr={2.21875}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {venueVal ? venueVal.label : 'Where'}
              </Typography>
              <ExpandMoreRoundedIcon />
            </SearchFilterContainer>
            <Popover
              open={isVenuePopoverOpen}
              anchorEl={typeof window === 'object' ? document.getElementById('venue-filter') : null}
              onClose={() => setIsVenuePopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={filterPopoverPaperProps}
            >
              <VenuePopoverContent />
            </Popover>
          </Box>
          {/* venue filter */}

          {/* date time filter */}
          <Box>
            <SearchFilterContainer id="time-filter" onClick={() => setIsTimepopoverOpen(true)}>
              <Typography
                variant="body2"
                mr={2.21875}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {timeVal ? timeVal.label : 'When'}
              </Typography>
              <ExpandMoreRoundedIcon />
            </SearchFilterContainer>

            <Modal
              isOpen={isTimePopoverOpen}
              onClose={() => setIsTimepopoverOpen(false)}
              maxWidth="xs"
              fullWidth
            >
              <DateTimePopoverContent />
            </Modal>
          </Box>
          {/* date time filter */}

          {/* guest filter */}
          <Box>
            <SearchFilterContainer id="guest-filter" onClick={() => setIsGuestPopoverOpen(true)}>
              <Typography
                variant="body2"
                mr={2.21875}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {guestVal > 0 ? guestVal : 'Guest'}
              </Typography>
              <ExpandMoreRoundedIcon />
            </SearchFilterContainer>

            <DialogAnimate
              open={isGuestPopoverOpen}
              onClose={() => setIsGuestPopoverOpen(false)}
              dialogProps={{ sx: { zIndex: 100000 } }}
            >
              <GuestPopoverContent />
            </DialogAnimate>
          </Box>
          {/* guest filter */}

          {/* price filter */}
          <Box>
            <SearchFilterContainer id="price-filter" onClick={() => setIsPricepopoverOpen(true)}>
              <Typography
                variant="body2"
                mr={2.21875}
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {priceVal ? priceVal.label : 'Price'}
              </Typography>
              <ExpandMoreRoundedIcon />
            </SearchFilterContainer>
            <Popover
              open={isPricePopoverOpen}
              anchorEl={typeof window === 'object' ? document.getElementById('price-filter') : null}
              onClose={() => setIsPricepopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={filterPopoverPaperProps}
            >
              <PricePopoverContent />
            </Popover>
          </Box>
          {/* price filter */}
        </Stack>
      </Grid>
    ),
    [
      PricePopoverContent,
      DateTimePopoverContent,
      GuestPopoverContent,
      VenuePopoverContent,
      filterPopoverPaperProps,
      isPricePopoverOpen,
      isTimePopoverOpen,
      isGuestPopoverOpen,
      isVenuePopoverOpen,
      priceVal,
      timeVal,
      guestVal,
      venueVal,
    ]
  );

  const [placeholderText, setPlaceholderText] = useState('What Are You Looking For?');

  return (
    <Container sx={{ paddingBottom: 1.25, marginTop: 0.75 }}>
      <SearchBarContainer>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={10} id="what-to-eat-field-input">
            <HtmlTooltip
              open={(isWhatToEatPopoverOpen && Boolean(searchref)) || isRecentKeywordPopoverOpen}
              title={
                whatToEatVal ? renderWhatToEatPopoverContent : renderRecentKeywordPopoverContent
              }
              placement="bottom-start"
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                anchorEl: searchref,
                placement: 'bottom-start',
                disablePortal: true,
              }}
            >
              <SearchInputContainer>
                <TextField
                  fullWidth
                  // id="what-to-eat-field-input"
                  variant="standard"
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    style: { fontSize: '14px', padding: 0 },
                  }}
                  placeholder="What Are You Looking For?"
                  sx={{
                    'input::placeholder': {
                      color: placeholderText === 'What Are You Looking For?' ? 'black' : 'grey',
                      fontWeight: placeholderText === 'What Are You Looking For?' ? 600 : 400,
                    },
                  }}
                  onFocus={(e) => {
                    e.target.placeholder = 'Chef, cuisine, or keywords';
                    setPlaceholderText(e.target.placeholder);
                    setSearchbarOnKeyboardState({ expanded: true });
                  }}
                  onBlur={(e) => {
                    e.target.placeholder = 'What Are You Looking For?';
                    setPlaceholderText(e.target.placeholder);
                    setSearchbarOnKeyboardState({ expanded: false });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch();
                    }
                  }}
                  size="small"
                  value={whatToEatVal}
                  onClick={(e) => {
                    setSearchRef(e.currentTarget);
                    setIsRecentKeywordPopoverOpen(whatToEatVal ? false : true);
                  }}
                  onChange={(e) => {
                    setWhatToEatVal({ value: e.target.value });
                    setShouldShowSuggestion(true);
                    setIsRecentKeywordPopoverOpen(e.target.value ? false : true);
                  }}
                />
              </SearchInputContainer>
            </HtmlTooltip>
          </Grid>

          <Grid item xs={2} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            <SearchIconButton id="SearchBar" onClick={onSearch}>
              <img
                src={resize_image({
                  url: 'https://images.potioneer.com/images-landing/icon_search_tJtmS2z0j.svg',
                  width: 26,
                  height: 26,
                })}
                alt="search"
              />
            </SearchIconButton>
          </Grid>
        </Grid>
      </SearchBarContainer>

      {renderSearchFilter}
    </Container>
  );
};

export default SearchBarV3MobileView;
