import { useEffect } from 'react';

export function useHideFacebookPopup(hide: boolean) {
  const id = 'style-for-hide-facebook-popup';
  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.remove();
    }
    if (hide) {
      const head = document.head || document.getElementsByTagName('head')[0];
      if (head) {
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.setAttribute('id', id);
        style.textContent = `#fb-root > div {visibility: hidden!important;}`;
        head.appendChild(style);
      }
    }
    return () => {
      const element = document.getElementById(id);
      if (element) {
        element.remove();
      }
    };
  }, [hide]);

  return [hide];
}
